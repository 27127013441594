import React, { useContext, useEffect, useState } from "react";
import businessData from "../../json/businessData.json";
import PaginationComponent from "../pagination/PaginationComponent";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import FormatNumber from "../utils/FormatNumber";
import { audioPlayer } from "./audioPlayer";
import {
  getEmailFromArray,
  getNumberFromArray,
} from "../../apis/commonFunction/commonFunction";
import { CallCenterContext } from "../../context/CallCenterContext";
import ModelUi from "../model/ModelUi";

const ClientLead = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const { makeCall } = useContext(CallCenterContext);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });
  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };

  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getProspectLead,
        {
          page,
          limit,
          search: search,
        },
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const splitAddressIntoLines = (address, maxLength = 20) => {
    if (!address) return null;

    const lines = [];
    for (let i = 0; i < address.length; i += maxLength) {
      lines.push(address.slice(i, i + maxLength));
    }
    return lines;
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Client Lead data
              </h4>
              <div className="row ">{/*/Col*/}</div>
              {/*/Row*/}

              {/*/Row*/}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {businessData.clientLead.map(
                                    (header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                        </td>
                                        <td>{item.FullName || "No Data"}</td>
                                        <td>
                                          {getNumberFromArray(item.phone) ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {getEmailFromArray(item.email) ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {splitAddressIntoLines(
                                            item.primaryResidenceStreetAddress ||
                                              "No Data"
                                          ).map((line, index) => (
                                            <div key={index}>{line}</div>
                                          ))}
                                        </td>
                                        <td>
                                          {item.primaryResidenceState ||
                                            "No Data"}
                                        </td>
                                        <td>{item.leadType || "No Data"}</td>
                                        <td>{item.leadSource || "No Data"}</td>
                                        <td>
                                          <span
                                            className="right_icon"
                                            onClick={() =>
                                              item?.phone.length === 1
                                                ? makeCall(item.phone[0].value)
                                                : setOpenModelForCallHandler(
                                                    item.phone
                                                  )
                                            }
                                          >
                                            <i className="material-symbols-outlined">
                                              call
                                            </i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default ClientLead;
