exports.getNumberFromArray = (phone) => {
    let returnData = "";
    for (let index = 0; index < phone.length; index++) {
        const element = phone[index];
        returnData = `${returnData} ${returnData ? "," : ""} ${element.value.includes("+1")
                ? element.value.split("+1")[1]
                : element.value
            }`;
    }
    return returnData;
};
exports.getEmailFromArray = (email) => {
    let returnData = "";
    for (let index = 0; index < email.length; index++) {
        const element = email[index];
        returnData = `${returnData} ${returnData ? "," : ""} ${element.value}`;
    }
    return returnData;
};