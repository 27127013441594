import React from "react";
import ModelUi from "../../../model/ModelUi";
import { useForm } from "react-hook-form";

const EmailModel = ({ show, onClose, onSave, email }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailInput: email, // Set initial value from props
    },
  });
  const onSubmit = (data) => {
   
    onSave(data.emailInput);
    reset();
    onClose();
  };

  return (
    <ModelUi show={show} handleClose={onClose} centered={true} title="Email">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="emailInput" className="col-sm-5 col-form-label">
              Add Email
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                placeholder="Please enter valid email address"
                className="form-control"
                maxLength={256}
                id="emailInput"
                {...register("emailInput", {
                  required: "Email address is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.emailInput && (
                <span className="text-danger">{errors.emailInput.message}</span>
              )}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">
            Save changes
          </button>
        </div>
      </form>
    </ModelUi>
  );
};

export default EmailModel;
