import React, { useState } from "react";
import businessData from "../../../json/businessData.json";
import PaginationComponent from "../../pagination/PaginationComponent";

import useContactHuman from "../../../hooks/useContactHuman";

const BusinessEmailPage = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const type = ["Human Resource", "Business Info"]; 

  const {
    error,
    contactDetails: allContactDetails,
    totalData,
  } = useContactHuman(type, page, limit, search);

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Business Emails
              </h4>
              <div className="row ">{/*/Col*/}</div>
              {/*/Row*/}

              {/*/Row*/}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {businessData.headers4.map(
                                    (header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                        </td>
                                        <td>
                                          {item.primaryName +
                                            " " +
                                            item.title || "No Data"}
                                        </td>

                                        <td>
                                          {item.businessName ||
                                            item.companyName ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {item.primaryContactNumber ||
                                            "No Data"}
                                        </td>

                                        <td>{item.email || "No Data"}</td>

                                        <td>{item.type || "No Data"}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessEmailPage;
