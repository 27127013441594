import React, { useState } from "react";
import ModelUi from "../../../model/ModelUi";
import { useForm } from "react-hook-form";

const PrimaryNameModel = ({ show, onClose, onSave, primaryName }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      primaryName: primaryName, // Set initial value from props
    },
  });

  const onSubmit = (data) => {
    onSave(data.primaryName); // Call onSave with the new phone number
    reset();
    onClose();
  };

  return (
    <ModelUi
      show={show}
      handleClose={onClose}
      centered={true}
      title="Enter Primary Name"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="contactInput" className="col-sm-5 col-form-label">
              Primary Name
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                placeholder="Please enter name"
                className="form-control"
                id="primaryName"
                maxLength={256}
                {...register("primaryName", {
                  required: "Primary Name is required",
                })}
                value={primaryName}
                onChange={(e) => onSave(e.target.value)}
              />
              {errors.primaryName && (
                <span className="text-danger">
                  {errors.primaryName.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">
            Save changes
          </button>
        </div>
      </form>
    </ModelUi>
  );
};

export default PrimaryNameModel;
