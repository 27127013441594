import React, { useContext, useEffect, useState } from "react";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Context } from "../../../context/Context";

const DependentsProfile = () => {
  const { jsonApi } = useApis();
  const { userData } = useContext(Context);
  const [userDetails, setUserDetails] = useState([]);

  const [error, setError] = useState(null);

  console.log(userData.name);
  const init = async () => {
    const details = await jsonApi(
      appConstant.getPersonSpouse,
      { userId: userData._id },
      null,
      true
    );
    console.log(details);
    if (!details.error) {
      setUserDetails(details?.data?.data || []); // Update state with actual data
      console.log(details?.data?.data);
    } else {
      setError(
        setUserDetails.message ? setUserDetails.message : "No data received"
      );
    }
  };

  useEffect(() => {
    init();
  }, [userData._id]);

  const dependents = userDetails?.dependents || {};

  return (
    <div className="container-fluid">
      <div className="row">
        {dependents.length > 0 ? (
          dependents.map((dependent, index) => (
            <div className="col-lg-6 mb-3" key={index}>
              <div className="card">
                <div className="card-body">
                  <div className="dependent-section">
                    <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 d-flex justify-content-between align-items-center">
                      Child #{index + 1}:
                    </h4>
                  </div>

                  <div className="form-group row">
                    <div className="col-6">
                      <label className="col-form-label font-weight-bold">Name :</label>
                      <div className="dependent-detail">
                        {dependent?.name || "No name"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Age</label>
                      <div className="dependent-detail">
                        {dependent?.age || "No age"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">DOB</label>
                      <div className="dependent-detail">
                        {dependent?.dob || "No DOB"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Gender</label>
                      <div className="dependent-detail">
                        {dependent?.gender || "No gender"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Social Security #
                      </label>
                      <div className="dependent-detail">
                        {dependent?.socialSecurity || "No SSN"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Custody Type</label>
                      <div className="dependent-detail">
                        {dependent?.custodyType || "No custody type"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Relationship Type
                      </label>
                      <div className="dependent-detail">
                        {dependent?.relationshipType || "No relationship type"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Drivers License #
                      </label>
                      <div className="dependent-detail">
                        {dependent?.driversLicense || "No license"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Drivers License State
                      </label>
                      <div className="dependent-detail">
                        {dependent?.driversLicenseState || "No state"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Years of Driving</label>
                      <div className="dependent-detail">
                        {dependent?.yearsOfDriving || "No years"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Blood/Relative Info
                      </label>
                      <div className="dependent-detail">
                        {dependent?.bloodRelativeInfo || "No info"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Biological Parent
                      </label>
                      <div className="dependent-detail">
                        {dependent?.biologicalParent || "No parent"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Current Living Profile
                      </label>
                      <div className="dependent-detail">
                        {dependent?.livingProfile || "No profile"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Grade Level</label>
                      <div className="dependent-detail">
                        {dependent?.gradeLevel || "No grade level"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Name of School</label>
                      <div className="dependent-detail">
                        {dependent?.schoolName || "No school"}
                      </div>
                    </div>
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">
                        Address of School
                      </label>
                      <div className="dependent-detail">
                        {dependent?.schoolAddress || "No address"}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                     <label className="col-form-label font-weight-bold">Major</label>
                      <div className="dependent-detail">
                        {dependent?.major || "No major"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No dependents</p>
        )}
      </div>
    </div>
  );
};

export default DependentsProfile;
