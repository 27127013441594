import React, { useContext, useEffect, useState } from "react";
import Login from "./pages/login/Login";
import { Context } from "./context/Context";
import { Route, Routes } from "react-router-dom";
import Aside from "./components/aside/Aside";
import CallCentre from "./components/callCentre/CallCentre";
import CoreModule from "./components/coreModule/CoreModule";
import useAuthApis from "./apis/useAuthApis";
import Hierarchy from "./pages/hierarchy/Hierarchy";
import Modules from "./components/modules/Modules";
import Loader from "./components/loader/Loader";
import CallHistory from "./components/callHistory/CallHistory";
import BusinessContact from "./components/businessContact/BusinessContact";
import BusinessInformation from "./components/businessContact/business/BusinessInformation";
import ContactHistory from "./components/businessContact/ContactHistory";
import Owners from "./components/businessContact/business/humanResources/Owners";
import KeyExecutive from "./components/businessContact/business/humanResources/KeyExecutive";
import Managers from "./components/businessContact/business/humanResources/Managers";
import Employee from "./components/businessContact/business/humanResources/Employee";
import AddTask from "./components/task/AddTask";
import ShowTask from "./components/task/ShowTask";
import ShowNotes from "./components/notes/ShowNotes";
import AddNotes from "./components/notes/AddNotes";
import EditTask from "./components/task/EditTask";
import EditNotes from "./components/notes/EditNotes";
import ContactProfile from "./components/businessContact/addPersonalContact/UserProfile";
import AddPersonal from "./components/businessContact/addPersonalContact/AddPersonal";
import SpouseDetails from "./components/businessContact/addPersonalContact/SpouseDetails";
import SpouseProfiles from "./components/businessContact/addPersonalContact/SpouseProfiles";
import DependentsForm from "./components/businessContact/addPersonalContact/DependentsForm";
import DependentsProfile from "./components/businessContact/addPersonalContact/DependentsProfile";
import AllDataPersonal from "./components/businessContact/addPersonalContact/AllDataPersonal";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import ProfilePasswordUpdate from "./pages/profilePasswordUpdate/ProfilePasswordUpdate";
import UpdateProfile from "./components/updateProfile/UpdateProfile";
import PersonalFavourites from "./components/businessContact/addPersonalContact/PersonalFavourites";
import BusinessContactData from "./components/businessContact/business/BusinessContactData";
import BusinessFavourites from "./components/businessContact/business/humanResources/BusinessFavorites";
import BusinessEmailPage from "./components/businessContact/business/BusinessEmailPage";
import ContactHumanData from "./components/businessContact/business/humanResources/ContactHumanData";
import SearchBased from "./components/businessContact/SearchBased";
import CreateEvents from "./components/events/CreateEvents";
import EventCalendar from "./components/events/EventCalendar";
import UpcomingEvents from "./components/events/UpcomingEvents";
import EditEvents from "./components/events/EditEvents";
import AddEmployee from "./pages/hierarchy/HierarchyFormComponents/AddEmployee";
import APIKey from "./components/apiKey/APIKey";
import UpdateCompanyProfile from "./components/updateCompanyProfile/UpdateCompanyProfile";
import UpdateAdminProfile from "./components/updateAdminProfile/UpdateAdminProfile";
import Dashboard from "./components/dashboard/Dashboard";
import AddBusinessContact from "./components/businessContact/business/AddBusinessContact";
import CallCenterContextProvider from "./context/CallCenterContext";
import ClientLead from "./components/LeadData/ClientsLead";
import ProspectsLead from "./components/LeadData/ProspectsLead";
import SubscriptionAdmin from "./components/suscribe/SubscriptionAdmin";
import SubscriptionCompany from "./components/suscribe/SubscriptionCompany";
import SubscriptionDetail from "./components/suscribe/SubscriptionDetail";

const Routing = () => {
  const { isAuthorized, isLoading, setIsLoading, userData } =
    useContext(Context);
  const [firstLoading, setFirstLoading] = useState(true);
  const { homeApi } = useAuthApis();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isAuthorized) {
        await homeApi();
      }
    }, [30000]);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    setIsLoading(true);
    await homeApi();
    setFirstLoading(false);
    setIsLoading(false);
  };

  return (
    <>
      <Loader open={isLoading} />

      {firstLoading ? (
        <></>
      ) : isAuthorized ? (
        userData.defaultPasswordReset ? (
          <CallCenterContextProvider>
            <Routes>
              <Route path="/" element={<Aside />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/call/centre" element={<CallCentre />} />
                <Route path="/hierarchy" element={<Hierarchy />} />
                <Route path="/core-module" element={<CoreModule />} />
                <Route path="/add-new-task" element={<AddTask />} />
                <Route path="/list-of-tasks" element={<ShowTask />} />
                <Route path="/list-of-notes" element={<ShowNotes />} />
                <Route path="/add-new-note" element={<AddNotes />} />
                <Route path="/list-of-tasks/:id" element={<EditTask />} />
                <Route path="/list-of-notes/:id" element={<EditNotes />} />
                <Route path="/calendar" element={<EventCalendar />} />
                <Route path="/add-new-event" element={<CreateEvents />} />
                <Route path="/upcoming-events" element={<UpcomingEvents />} />
                <Route path="/upcoming-events/:id" element={<EditEvents />} />
                <Route path="/add-employee" element={<AddEmployee />} />
                <Route path="/api-key" element={<APIKey />} />
                <Route
                  path="/profile-password-update"
                  element={<ProfilePasswordUpdate />}
                />
                <Route path="/profile" element={<UpdateProfile />} />
                <Route
                  path="/company-profile"
                  element={<UpdateCompanyProfile />}
                />
                <Route path="/admin-profile" element={<UpdateAdminProfile />} />
                <Route path="/module" element={<Modules />} />
                <Route path="/call-history" element={<CallHistory />} />

                <Route path="/business-contact" element={<BusinessContact />}>
                  <Route
                    path="/business-contact"
                    element={<ContactHistory />}
                  />
                  <Route path="owners" element={<Owners />} />
                  <Route path="key_executive" element={<KeyExecutive />} />
                  <Route path="managers" element={<Managers />} />
                  <Route path="employee" element={<Employee />} />
                  <Route path="contact_profiles" element={<SpouseProfiles />} />
                  <Route path="spouse-profiles" element={<SpouseProfiles />} />
                  <Route
                    path="dependent-profile"
                    element={<DependentsProfile />}
                  />
                  <Route
                    path="business-Information"
                    element={<BusinessInformation />}
                  />
                  <Route
                    path="business-favorites"
                    element={<BusinessFavourites />}
                  />
                  <Route
                    path="personal-favorites"
                    element={<PersonalFavourites />}
                  />

                  <Route path="add-personal-contact" element={<AddPersonal />}>
                    <Route
                      path="contact-profile"
                      element={<ContactProfile />}
                    />
                    <Route path="spouse-details" element={<SpouseDetails />} />
                    <Route path="dependent-form" element={<DependentsForm />} />
                  </Route>

                  <Route path="edit-personal-contact" element={<AddPersonal />}>
                    <Route
                      path="contact-profile"
                      element={<ContactProfile />}
                    />
                    <Route path="spouse-details" element={<SpouseDetails />} />
                    <Route path="dependent-form" element={<DependentsForm />} />
                  </Route>

                  <Route
                    path="add-business-contact"
                    element={<AddBusinessContact />}
                  />
                  <Route
                    path="contactHumanData"
                    element={<ContactHumanData />}
                  />
                  <Route path="personal/data" element={<AllDataPersonal />} />
                  <Route
                    path="businessPersonalData"
                    element={<BusinessContactData />}
                  />
                  <Route
                    path="businessEmailPage"
                    element={<BusinessEmailPage />}
                  />
                  <Route path="search" element={<SearchBased />} />
                  <Route path="lead/client" element={<ClientLead />} />
                  <Route path="lead/prospects" element={<ProspectsLead />} />
                </Route>

                <Route
                  path="/subscription-admin"
                  element={<SubscriptionAdmin />}
                />
                <Route
                  path="/subscription-company"
                  element={<SubscriptionCompany />}
                />
                <Route
                  path="/company/details/:id"
                  element={<SubscriptionDetail />}
                />
              </Route>
            </Routes>
          </CallCenterContextProvider>
        ) : (
          <Routes>
            <Route
              path="/password-update"
              element={<ProfilePasswordUpdate />}
            />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
        </Routes>
      )}
    </>
  );
};

export default Routing;
