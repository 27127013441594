import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import AllDataPersonal from "./AllDataPersonal";

const AddPersonal = () => {
  let location = useLocation();
  const { jsonApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [isEditing, setIsEditing] = useState(false);


  const init = async () => {
    console.log(location.pathname.includes("add-personal-contact"));
    if (location.pathname.includes("add-personal-contact")) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
    if (!searchParams.get("id")) {
      const res = await jsonApi(appConstant.createUserDetail, null, {}, false);
      if (!res.error) {
        setSearchParams({
          id: res.data.data._id,
        });
        setData(res.data.data);
      }
    } else {
      const res = await jsonApi(
        appConstant.createUserDetail,
        { id: searchParams.get("id") },
        {},
        false
      );
    }
  };

  useEffect(() => {
    init();
  }, [searchParams]);

  const deleteUserDetail = async (id) => {
    if (id) {
      const result = await jsonApi(appConstant.deleteUser, { id }, null, null);
      if (!result.error) {
        init();
      } else {
        console.error("Failed to delete core module:", result.data);
      }
    }
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="row">
            <div className="col-12 align-self-center">
              <h3 className="page-title text-truncate text-dark font-weight-medium mx-3">
               

                {isEditing ? "Edit Personal Contact" : "Add Personal Contact"}
              </h3>
              {searchParams.get("id") ? (
                <div className="row mt-4 mb-4">
                  <div className="col-lg-8">
                    <div className="d-flex">
                      <Link
                        to={`contact-profile?id=${searchParams.get("id")}`}
                        className="btn btn-primary rounded-pill m-2"
                      >
                      
                        {isEditing ? "Edit Contact" : "Add Contact"}
                      </Link>

                      <Link
                        to={`spouse-details?id=${searchParams.get("id")}`}
                        className="btn btn-primary rounded-pill m-2"
                      >
                     
                        {isEditing
                          ? "Edit Spouse Details"
                          : "Add Spouse Detail"}
                      </Link>
                      <Link
                        to={`dependent-form?id=${searchParams.get(
                          "id"
                        )}&isEditing=${isEditing}`}
                        className="btn btn-primary rounded-pill m-2"
                      >
                      
                        {isEditing ? "Edit Dependent" : "Add Dependent"}
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <Outlet />
              <AllDataPersonal
                onEditChange={setIsEditing}
                deleteUserDetail={deleteUserDetail}
                classPage={false}
              />
              {/* Start Page Content */}

              {/* end row*/}
            </div>
          </div>

          {/* multi-column ordering */}
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default AddPersonal;
