import { useContext } from "react";
import axios from "axios";
import { Context } from "../context/Context";
import { useNavigate } from "react-router-dom";
import appConstant from "./../json/appConstant.json"
const ENDPOINT = process.env.REACT_APP_ENDPOINT;


const useAuthApis = () => {

  const navigate = useNavigate();

  axios.defaults.headers.common["Authorization"] = localStorage.getItem("bearerToken");
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const {
    setUserData,
    setIsAuthorized,
    setIsLoading
  } = useContext(Context);
  axios.interceptors.response.use(
    response => response, // If the response is successful, just return it
    error => {
      if (error.response && error.response.status === 409) {
        setIsAuthorized(false)
        setUserData({})
        navigate("/")
      }
      return Promise.reject(error);
    }
  );


  const loginApi = async (data) => {
    try {

      const res = await axios[appConstant.login.method](`${ENDPOINT}${appConstant.login.path}`, data);
      setUserData(res.data.data.user);
      localStorage.setItem("bearerToken", res.data.data.token);
      setIsAuthorized(true);
      if (!res.data.data.user.defaultPasswordReset) {
        navigate("/password-update");
      }else {
        navigate("/dashboard");

      }
      // return res.data.message;
      return { error: false, message: res.data.data.message }
    } catch (error) {
      return { error: true, message: error.response.data.message }
    }
  }
  
  const changeProfilePasswordApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios[appConstant.updatePassword.method](`${ENDPOINT}${appConstant.updatePassword.path}`, data);
 
      setIsAuthorized(true);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const homeApi = async (data) => {
    try {

      const res = await axios[appConstant.home.method](`${ENDPOINT}${appConstant.home.path}`,);
      setUserData(res.data.user);
      setIsAuthorized(true);
      return { error: false, message: res.data.data.message }
    } catch (error) {

    }
  }

  const logOutApi = async () => {
    try {
      const res = await axios[appConstant.logout.method](`${ENDPOINT}${appConstant.login.path}`);
      localStorage.removeItem("bearerToken")
      setUserData({})
      setIsAuthorized(false)
      navigate("/")
 
    }
    catch (error) {
      navigate("/")
    }
  }
  const getImageApi = async (key) => {
    try {
      const res = await axios.get(`${ENDPOINT}/image/${key}`)

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  }

  const forgetPasswordApi = async (data) => {
    try {
      const res = await axios[appConstant.forgetPassword.method](
        `${ENDPOINT}${appConstant.forgetPassword.path}`,
        data
      );
      if (res && res.data && res.data) {
        // Optionally log the response for debugging
        return {
          error: false,
          message: res || "Success",
          data: res
        };
      } else {
        // Handle unexpected response structure
        return { error: true, message: "Unexpected response structure" };
      }
    } catch (error) {
      // Handle errors from API call
      return {
        error: true,
        message: error.response?.data?.message || "An error occurred"
      };
    }
  };


  const resetPasswordApi = async ({
    otp,
    password,
    passwordConfirm,
    email,
  }) => {
    try {
      const res = await axios[appConstant.resetPassword.method](
        `${ENDPOINT}${appConstant.resetPassword.path}`,
        { otp, password, passwordConfirm, email }
      );
      if (res.data && res.data.message) { 
        return { error: false, message: 'Password changed successfully' };
      } else {
        return { error: true, message: 'Something went wrong' };
      }
    } catch (error) {
      return {
        error: true,
        message: error.response?.data?.message || "An error occurred"
      };
    }
  };
  

  return {
    loginApi,
    logOutApi,
    homeApi,
    getImageApi,
    forgetPasswordApi,
    resetPasswordApi,
    changeProfilePasswordApi,

  };

};

export default useAuthApis;