import React, { useState } from "react";

const PaginationComponent = ({
  totalData,
  page,
  limit,
  setPage,
  setLimit,
  children,
  setSearch,
}) => {


  const totalPages = Math.ceil(totalData / limit);

  const [pageRangeStart, setPageRangeStart] = useState(1);


  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      updatePageRange(newPage)
    }
  };

  const updatePageRange=(currentPage)=>{
    if(currentPage>=pageRangeStart+5){
      setPageRangeStart(pageRangeStart+5);
    }else if (currentPage<pageRangeStart){
      setPageRangeStart(pageRangeStart-5>0 ? pageRangeStart-5 :1)
    }
  }

  const getVisiblePages=()=>{
    const pages=[];
    const end=Math.min(pageRangeStart+4,totalPages)
    for(let i=pageRangeStart;i<=end;i++){
      pages.push(i)
    }
    return pages;
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length">
            <label className="form-label">
              Show
              <select
                className="form-select form-select-sm ms-2"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_filter">
            <label className="form-label">
              Search:
              <input
                type="search"
                maxLength={256}
                className="form-control form-control-sm ms-2"
                onChange={(e) => setSearch(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>

      {children}

      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {(page - 1) * limit + 1} to{" "}
            {Math.min(page * limit, totalData)} of {totalData} entries
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <div className="dataTables_paginate">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  Previous
                </button>
              </li>
              {/* Render page numbers dynamically */}
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${page === index + 1 ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {getVisiblePages().map((p)=>(
                <li
                key={p}
                className={`page-item ${page === p  ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </button>
              </li>
              ))}
              <li
                className={`page-item ${page === totalPages ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationComponent;




// const getVisiblePages = () => {
//   const pages = [];
//   const maxVisiblePages = 5; // Total visible pages on the UI
//   let start = Math.max(1, pageRangeStart);
//   let end = Math.min(totalPages, start + maxVisiblePages - 1);

//   // Show first page if not in the range
//   if (start > 1) {
//     pages.push(1);
//   }

//   // Add previous ellipsis if necessary
//   if (start > 2) {
//     pages.push('...');
//   }

//   // Push current range of pages
//   for (let i = start; i <= end; i++) {
//     pages.push(i);
//   }

//   // Show next ellipsis if the end page is less than totalPages
//   if (end < totalPages - 1) {
//     pages.push('...');
//   }

//   // Show the last page
//   if (end < totalPages) {
//     pages.push(totalPages);
//   }

//   return pages;
// };

// Update the Pagination Render

// Then, in your pagination render, you’ll need to handle the case for the ellipses:

// javascript

// <ul className="pagination justify-content-end">
//   <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
//     <button
//       className="page-link"
//       onClick={() => handlePageChange(page - 1)}
//       disabled={page === 1}
//     >
//       Previous
//     </button>
//   </li>

//   {getVisiblePages().map((p) => {
//     if (p === '...') {
//       return (
//         <li key={p} className="page-item disabled">
//           <span className="page-link">{p}</span>
//         </li>
//       );
//     }
//     return (
//       <li key={p} className={`page-item ${page === p ? "active" : ""}`}>
//         <button
//           className="page-link"
//           onClick={() => handlePageChange(p)}
//         >
//           {p}
//         </button>
//       </li>
//     );
//   })}

//   <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
//     <button
//       className="page-link"
//       onClick={() => handlePageChange(page + 1)}
//       disabled={page === totalPages}
//     >
//       Next
//     </button>
//   </li>
// </ul>