import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useParams } from "react-router-dom";
import { validateName, validateDescription } from "../validation/Validation";

const EditTask = () => {
  const { id } = useParams();
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Function to fetch task data
  const fetchTask = useCallback(async () => {
    try {
      const response = await jsonApi(appConstant.getByIdTask, { id });

      if (response.error) {
        setError(response.error.message || "An error occurred");
      } else {
        reset({
          taskName: response.data.taskName,
          taskDescription: response.data.taskDescription,
        });
        setError("");
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  }, [id, reset]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const onSubmit = async (data) => {
    try {
      const response = await jsonApi(appConstant.editTask, { id }, data, null);

      if (!response.error) {
        setSuccessMessage("Task updated successfully");
        setError("");
        fetchTask();
      } else {
        setError(response.error.message || "An error occurred");
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="db-search d-none d-lg-inline-block"></div>
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Edit New Task
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Task Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="taskName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="Task Name"
                            {...field}
                            className={`form-control ${errors.taskName ? "is-invalid" : ""
                              }`}
                            {...register("taskName", {
                              required: "Task Name is required",
                              validate: (value) =>
                                validateName(value, "task name"),
                            })}
                          />
                        )}
                      />
                      {errors.taskName && (
                        <p className="text-danger">{errors.taskName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Task Description
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        id="taskDescription"
                        name="taskDescription"
                        control={control}
                        render={({ field }) => (
                          <>
                            <textarea
                              rows="4"
                              maxLength={1024}
                              placeholder="Task Description"
                              className={`form-control ${errors.taskDescription ? "is-invalid" : ""
                                }`}
                              {...register("taskDescription", {
                                required: "Task Description is required",
                                validate: (value) =>
                                  validateDescription(value) || "Invalid description",
                              })}
                            />
                            <div className="text-muted mt-1">
                              {field.value ? field.value.length : 0} / 1024
                            </div>
                          </>
                        )}
                      />
                      {errors.taskDescription && (
                        <p className="text-danger">{errors.taskDescription.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update Task
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
            />
          </div>
        </div>
      </div>
    
    </>
  );
};

export default EditTask;
