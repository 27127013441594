// hooks/useContactHuman.js
import { useState, useEffect, useCallback } from 'react';
import appConstant from '../json/appConstant.json';
import useApis from '../apis/useApis';

const useContactHuman = (type, page, limit, search) => {
    const { jsonApi } = useApis();
    const [error, setError] = useState("");
    const [contactDetails, setContactDetails] = useState([]);
    const [totalData, setTotalData] = useState(0);
  
    const fetchContactHuman = useCallback(async () => {
      try {
        const contactHuman = await jsonApi(
          appConstant.getContactHuman,
          { page, limit, search, type },
          null,
          true
        );
  
        if (!contactHuman.error) {
          setContactDetails(contactHuman.data.data || []);
          setTotalData(contactHuman.data.total);
        } else {
          setError(contactHuman.message || "No data received");
        }
      } catch (error) {
        setError("An unexpected error occurred");
        console.error("Error:", error);
      }
    }, [jsonApi, type, page, limit, search]);
  
    useEffect(() => {
      fetchContactHuman();
    }, []);
  
    return { error, contactDetails, totalData, fetchContactHuman };
  };

export default useContactHuman;
