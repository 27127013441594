import React, { createContext, useContext, useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import { Context } from "../../src/context/Context";
import useApis from "../../src/apis/useApis";
import appConstant from "./../../src/json/appConstant.json";
import ModelUi from "../components/model/ModelUi";
import { useForm } from "react-hook-form";

export const CallCenterContext = createContext();

const CallCenterContextProvider = ({ children }) => {
  const [deviceInputAvailable, setDeviceInputAvailable] = useState([]);
  const [defaultDeviceInput, setDefaultDeviceInput] = useState("");
  const [deviceOutputAvailable, setDeviceOutputAvailable] = useState([]);
  const [defaultDeviceOutput, setDefaultDeviceOutput] = useState("");
  const [from, setFrom] = useState("");
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [callSessionId, setCallSessionId] = useState("fgdfgfg");
  const [inCall, setInCall] = useState(false); // Flag for active call
  const [callDuration, setCallDuration] = useState(0); // in seconds
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const [durationInterval, setDurationInterval] = useState(null); // store interval ID
  const [currentCallData, setCurrentCallData] = useState(null); // store interval ID
  const [picked, setPicked] = useState([]);
  const [callCenterContextCount, setCallCenterContextCount] = useState(0);

  const { userData, selectedStatus } = useContext(Context);
  const { jsonApi, mediaApi } = useApis();
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  const [openNote, setOpenNote] = useState(false);

  const init = async () => {
    const res = await jsonApi(
      appConstant.createCallToken,
      null,
      { identity: userData._id },
      null
    );
    setFrom(userData.twilioNumber);

    const token = res.data.token;
    const newDevice = new Device(token, {
      logLevel: 1,
      codecPreferences: ["opus", "pcmu"],
    });
    setDevice(newDevice);
  };
  useEffect(() => {
    if (selectedStatus === "Available") {
      init();
    } else {
      setDevice(null);
    }
  }, [selectedStatus]);

  const resetCallSessionId = () => {
    const newSessionId = `${Math.trunc(
      Math.random() * 9999999
    )}${new Date().getTime()}`;
    setCallSessionId(newSessionId);
    return newSessionId;
  };
  useEffect(() => {
    if (device) {
      try {
        addDeviceListeners(callSessionId);
        device.register();
      } catch (err) {
        console.error("Error in device setup:", err);
      }

      return () => {
        addDeviceListeners(callSessionId);
      };
    }
  }, [device]);

  const updateDevices = (selectEl, selectedDevices) => {
    device.audio.availableOutputDevices.forEach(function (device, id) {
      let isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(function (device) {
        if (device.deviceId === id) {
          isActive = true;
        }
      });
    });
  };
  const updateAllAudioDevices = () => {
    if (device) {
      updateDevices("speakerDevices", device.audio.speakerDevices.get());
      updateDevices("ringtoneDevices", device.audio.ringtoneDevices.get());
    }
  };
  const handleEndCall = async () => {
    if (call) {
      call.disconnect(); // End the call
      setInCall(false);

      if (durationInterval) {
        clearInterval(durationInterval);
      }
      setCallDuration(0);
      setOpenNote(true);
    }
  };

  const makeCall = async (phoneNumber) => {
    if (device) {
      const callId = resetCallSessionId();
      const number = phoneNumber;

      const params = { To: number, session_id: callId };
      setCallSessionId(callId);
      const newCall = await device.connect({ params });
      // Start tracking the duration
      if (durationInterval) {
        clearInterval(durationInterval);
      }

      setCallDuration(0);
      const interval = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      setDurationInterval(interval); // Save interval ID for later clearing

      newCall.on("accept", async () => {
        console.log("Call accepted");
        setCallCenterContextCount((pre) => pre + 1);
      });

      newCall.on("disconnect", async () => {
        try {
          console.log("Call disconnected");
          setInCall(false);
          setPicked("");
          // Stop tracking the duration
          setCallCenterContextCount((pre) => pre + 1);
          if (durationInterval) {
            clearInterval(durationInterval);
          }

          setCallDuration(0);
        } catch (error) {
          console.log("error", error);
        }
        // Reset call duration
      });

      newCall.on("reject", async () => {
        try {
          setPicked("");
          setInCall(false);
          console.log("Call rejected");
          setCallCenterContextCount((pre) => pre + 1);
          if (durationInterval) {
            clearInterval(durationInterval);
          }

          setCallDuration(0); // Reset call duration
        } catch (error) {
          console.log("eeror", error);
        }
      });
      setCall(newCall);
      setPicked(`Calling ${number}`);
      addDeviceListeners(callId);
      setInCall(true);
      await jsonApi(
        appConstant.createCallHistory,
        null,
        { phone: number, userNumber: from, type: "Test" },
        true
      );
    }
  };

  const addDeviceListeners = (seId) => {
    device.on("registered", function () {
      console.log("Twilio.Device Ready to make and receive calls!");
    });
    device.on("tokenWillExpire", async () => {
      const response = await init();
      device.updateToken(response.token);
    });
    device.on("error", function (error) {
      console.error("Twilio.Device Error: " + error.message);
    });
    device.on("incoming", (callData) => handleIncomingCall(callData, seId));
    device.audio.on("deviceChange", () => {
      updateAllAudioDevices.bind(device);
      const deviceOutputAvailableArray = [];
      device.audio.availableOutputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceOutputAvailableArray.push({ label: device.label, id: id });
      });
      if (deviceOutputAvailableArray.length !== 0) {
        setDefaultDeviceOutput(deviceOutputAvailableArray[0].id);
      }
      setDeviceOutputAvailable(deviceOutputAvailableArray);
      const deviceInputAvailableArray = [];
      device.audio.availableInputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceInputAvailableArray.push({ label: device.label, id: id });
      });
      setDeviceInputAvailable(deviceInputAvailableArray);
      if (deviceInputAvailableArray.length !== 0) {
        setDefaultDeviceInput(deviceInputAvailableArray[0].id);
      }
    });

    device.on("connect", async () => {
      console.log("Call connected");
      setOpenIncomingModal(false);
      setInCall(true);
      setPicked("");
      setCallCenterContextCount((pre) => pre + 1);
    });

    device.on("disconnect", async () => {
      try {
        console.log("Call disconnected");
        setOpenIncomingModal(false);
        setInCall(false);
        setCall(null);
        setPicked("");
        // Stop tracking the duration
        setCallCenterContextCount((pre) => pre + 1);
        if (durationInterval) {
          clearInterval(durationInterval);
        }
        setCallDuration(0); // Reset call duration
      } catch (error) {
        console.log("eeror", error);
      }
    });

    const handleIncomingCall = async (callData, sessionIdData) => {
      console.log("callSessionId", sessionIdData);
      delay(3000);
      setCall(callData);
      setInCall(true);
      setOpenIncomingModal(true);
      setPicked(`Received Call ${callData.parameters.From}`);

      console.log(callData?.parameters?.From);
      setCallCenterContextCount((pre) => pre + 1);
      const resData = await jsonApi(
        appConstant.getCurrentLeadCall,
        null,
        { phone: callData?.parameters?.From },
        true
      );
      if (!resData.error) {
        setCurrentCallData(resData.data.data);
      } else {
        console.error(resData.message);
        setCurrentCallData(null);
      }
    };
  };

  const handleAcceptCall = () => {
    if (call) {
      call.accept(); // Accept the incoming call
      setOpenIncomingModal(false);
      setInCall(true);
      if (durationInterval) {
        clearInterval(durationInterval);
      }
      setCallDuration(0);

      const interval = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      setDurationInterval(interval); // Save interval ID for later clearing
    }
  };

  const handleRejectCall = () => {
    try {
      if (call) {
        call.reject(); // Reject the incoming call
        setInCall(false);
        setOpenIncomingModal(false);
        if (durationInterval) {
          clearInterval(durationInterval);
        }

        setCallDuration(0); // Reset call duration
      }
    } catch (error) {
      console.log("eeror", error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (currentCallData) {
      console.log("first");
      // const leadUpdtaedData = {
      //   id: currentCallData._id,
      //   notes: data.notes,
      // };
      // console.log("gdf", leadUpdtaedData);
      console.log(currentCallData?._id);
      const updateLead = await jsonApi(
        appConstant.updateLeadData,
        { id: currentCallData._id }, // Pass ID as a query parameter
        { notes: data.notes }, // Pass the notes in the body
        null
      );
      console.log(data.notes);
      if (!updateLead.error) {
        console.log("Lead updated successfully");
      } else {
        console.error("Error updating lead:", updateLead.error);
      }
    }
  };

  return (
    <CallCenterContext.Provider
      value={{
        callCenterContextCount,
        makeCall,
        picked,
        setPicked,
        handleEndCall,
        inCall,
        callDuration,
      }}
    >
      {children}
      <ModelUi
        show={openIncomingModal}
        handleClose={() => setOpenIncomingModal(false)}
        title={currentCallData ? "Incoming Lead" : "Incoming Call"}
      >
        {currentCallData ? (
          <>
            <h3>You have an incoming lead...</h3>
            <h4>Lead Information</h4>
            <p>
              <strong>Name:</strong> {currentCallData.primaryName}
            </p>
            <p>
              <strong>Contact Number:</strong>{" "}
              {currentCallData.primaryContactNumber}
            </p>
            <p>
              <strong>Email:</strong> {currentCallData.email}
            </p>
            <p>
              <strong>Street Address:</strong> {currentCallData.streetAddress}
            </p>
            <p>
              <strong>City:</strong> {currentCallData.city}
            </p>
            <p>
              <strong>State:</strong> {currentCallData.state}
            </p>
            <p>
              <strong>Zip Code:</strong> {currentCallData.zipCode}
            </p>
            <p>
              <strong>Lead Type:</strong> {currentCallData.leadType}
            </p>
            <p>
              <strong>Lead Source:</strong> {currentCallData.leadSource}
            </p>
          </>
        ) : (
          <h3>You have an incoming call...</h3>
        )}

        {!currentCallData && <h4>From: {call?.parameters?.From}</h4>}

        <div className="text-center">
          <button
            type="button"
            className="btn btn-primary m-2"
            onClick={handleAcceptCall}
          >
            Accept Call
          </button>
          <button
            type="button"
            className="btn btn-danger m-2"
            onClick={handleRejectCall}
          >
            Reject Call
          </button>
        </div>
      </ModelUi>
      {currentCallData?.type === "Lead" && (
        <ModelUi
          show={openNote}
          handleClose={() => setOpenNote(false)}
          title="Add a Note/Experience"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {/* <textarea
            rows="5"
            cols="50"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Enter your note here..."
          /> */}
              <textarea
                rows="5"
                cols="50"
                placeholder="Notes: 01/01/22, 6:00 AM John Doe"
                id="notes"
                {...register("notes")}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => {
                  // saveNote(note);
                  setOpenNote(false);
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary m-2"
                onClick={() => {
                  setOpenNote(false);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </ModelUi>
      )}
    </CallCenterContext.Provider>
  );
};

export default CallCenterContextProvider;
