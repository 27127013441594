import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2, Trash } from "react-feather";
import businessData from "../../../../json/businessData.json";
import PaginationComponent from "../../../pagination/PaginationComponent";
import AddressModel from "./AddressModel";
import EmailModel from "./EmailModel";
import PrimaryNumberModel from "./PrimaryNumberModel";
import appConstant from "../../../../json/appConstant.json";
import useApis from "../../../../apis/useApis";
import FavButton from "./FavButton";
import PrimaryNameModel from "./PrimaryNameModel";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validatePercentOwn,
} from "../../../validation/Validation";
import { PhoneInput } from "react-international-phone";
import FormatNumber from "../../../utils/FormatNumber";

const Owners = () => {
  const formRef = useRef(null); // Step 1: Create a reference
  const [isEditing, setIsEditing] = useState(false); // State for edit mode
  const [editData, setEditData] = useState(null);
  const [openNameModel, setOpenNameModel] = useState(false);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const [openEmailModel, setOpenEmailModel] = useState(false);
  const [openPrimaryNumberModel, setOpenPrimaryNumberModel] = useState(false);
  const [primaryName, setPrimaryName] = useState([]);
  const [email, setEmail] = useState([]);
  const [addressState, setAddressState] = useState([]);
  const [primaryContactNumber, setPrimaryContactNumber] = useState([]);
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [ownerContactDetails, setOwnerContactDetails] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleMessage = () => {
    setInterval(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      type: "Human Resource",
    };

    try {
      let response;
      if (isEditing && editData) {
        response = await jsonApi(
          appConstant.editContactHuman,
          { id: editData._id },
          formData,
          null
        );
      } else {
        response = await jsonApi(
          appConstant.createContactHuman,
          null,
          formData,
          null
        );
      }
      // console.log("API Response:", response); // Debugging line
      if (!response.error) {
        setError("");
        setSuccessMessage(response.data.message);
        reset();
        setIsEditing(false);
        fetchContactHuman();
        handleMessage();
      } else {
        // Here we handle the specific case for the email already in use
        if (
          response.status === 400 &&
          response.message === "Email is already in use."
        ) {
          setError("This email is already in use. Please use a different one.");
        } else {
          setError(response.message);
        }
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  // const handleUsernameChange = async (e) => {
  //   if (!(e.target.value.length === 0)) {
  //     const res = await jsonApi(
  //       appConstant.checkOldEmailAndUsername,
  //       null,
  //       { text: e.target.value},
  //       true
  //     );
  //     if (res.error) {
  //       setError(e.target.name, {
  //         type: "manual",
  //         message: res.message,
  //       });
  //     } else {
  //       clearErrors(e.target.name);
  //     }
  //   }
  // };
  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactHuman,
        {
          page,
          limit,
          employmentLevel: "Owner",
          search: search,
          type: "Human Resource",
        },
        null,
        true
      );
      if (!contactHuman.error) {
        setOwnerContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const handleAddOwnerClick = () => {
    // Reset the form fields when adding a new owner
    reset();
    setIsEditing(false);
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEditClick = (data) => {
    setIsEditing(true);
    setEditData(data);
    setValue("companyName", data.companyName);
    setValue("primaryContact", data.primaryContact);
    setValue("email", data.email);
    setValue("streetAddress", data.streetAddress);
    setValue("primaryResponsibility", data.primaryResponsibility);
    setValue("suiteNumber", data.suiteNumber);
    setValue("zip", data.zip);
    setValue("state", data.state);
    setValue("city", data.city);
    setValue("isOwner", data.isOwner);
    setValue("title", data.title);
    setValue("primaryContactNumber", data.primaryContactNumber);
    setValue("percentOwn", data.percentOwn);

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSaveEmail = (newEmail) => {
    setEmail(newEmail);
    setValue("email", newEmail);
  };
  const handleSaveStreetAddress = (newStreetAddress) => {
    setAddressState(newStreetAddress);
    setValue("streetAddress", newStreetAddress);
  };

  const handleSavePrimaryContactNumber = (newPrimaryContactNumber) => {
    setPrimaryContactNumber(newPrimaryContactNumber);
    setValue("primaryContactNumber", newPrimaryContactNumber);
  };

  const handleSavePrimaryName = (newName) => {
    setPrimaryName(newName);
    setValue("primaryName", newName);
  };

  const splitAddressIntoLines = (address, maxLength = 30) => {
    if (!address) return null;

    const lines = [];
    for (let i = 0; i < address.length; i += maxLength) {
      lines.push(address.slice(i, i + maxLength));
    }

    return lines;
  };

  const handleNameModelOpen = () => setOpenNameModel(true);
  const handleNameModelClose = () => setOpenNameModel(false);
  const handleAddressModelOpen = () => setOpenAddressModel(true);
  const handleAddressModelClose = () => setOpenAddressModel(false);
  const handleEmailModelOpen = () => setOpenEmailModel(true);
  const handleEmailModelClose = () => setOpenEmailModel(false);
  const handlePrimaryNumberModelOpen = () => setOpenPrimaryNumberModel(true);
  const handlePrimaryNumberModelClose = () => setOpenPrimaryNumberModel(false);

  const deleteContact = async (id) => {
    console.log(id);
    if (id) {
      const result = await jsonApi(
        appConstant.deleteContactHuman,
        { id },
        null,
        null
      );
      if (!result.error) {
        fetchContactHuman();
      } else {
        console.error("Failed to delete core module:", result.data);
      }
    }
  };

  const handleDeleteClick = async (id) => {
    await deleteContact(id);
    fetchContactHuman();
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <div className="row mb-5">
                <div className="col-lg-4">
                  {businessData.fields.map((field, index) => (
                    <div
                      className="row align-items-center mb-3 mb-sm-0"
                      key={index}
                    >
                      <label className="col-md-5 col-sm-4 mb-0 font-weight-bold">
                        {field.label}
                      </label>
                      <div className="col-md-7 col-sm-8">
                        <input
                          type={field.type}
                          className={field.class}
                          defaultValue={field.value}
                          readOnly={true}
                          maxLength={256}
                        />
                      </div>
                    </div>
                  ))}
                  {/*/Row*/}
                </div>
                {/*/Col*/}
                <div className="col-lg-8">
                  <textarea
                    className="form-control"
                    placeholder="Notes: 01/01/22, 6:00 AM John Doe"
                    id="notes"
                    rows={8}
                    {...register("notes")}
                  />
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
              <div className="row">
                <div className="col-12 mb-4">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                    Owners
                    <button
                      type="button"
                      className="btn btn-sm btn-primary btn-rounded px-4 ml-4"
                      data-toggle="modal"
                      data-target="#addOwnerModal"
                      onClick={handleAddOwnerClick} // Attach click handler
                    >
                      Add Owner
                    </button>
                  </h4>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
              <div className="row">
                <div className="col-12 mb-5">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {businessData.headers.map((header, index) => (
                                    <th scope="col" key={index}>
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {ownerContactDetails &&
                                ownerContactDetails.length > 0 ? (
                                  ownerContactDetails
                                    .filter(
                                      (item) => item.employmentLevel === "Owner"
                                    )
                                    .map((item, index) => {
                                      return (
                                        <tr
                                          style={{ textAlign: "center" }}
                                          key={index}
                                        >
                                          <td>
                                            {(page - 1) * limit + index + 1}
                                            <span className="p-1">
                                              {" "}
                                              <FavButton
                                                item={item}
                                                init={fetchContactHuman}
                                              />
                                            </span>
                                          </td>

                                          <td>
                                            {item.primaryName || "No Data"}
                                          </td>
                                          <td>
                                            {FormatNumber(
                                              item.primaryContactNumber
                                            )}
                                          </td>
                                          <td>{item.email || "No Data"}</td>
                                          <td>
                                            {splitAddressIntoLines(
                                              item.streetAddress || "No Data"
                                            ).map((line, index) => (
                                              <div key={index}>{line}</div>
                                            ))}
                                          </td>
                                          <td>
                                            {splitAddressIntoLines(
                                              item.primaryResponsibility ||
                                                "No Data"
                                            ).map((line, index) => (
                                              <div key={index}>{line}</div>
                                            ))}
                                          </td>
                                          <td>
                                            {" "}
                                            <Edit2
                                              style={{ cursor: "pointer" }}
                                              className="ml-1"
                                              onClick={() =>
                                                handleEditClick(item)
                                              }
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <Trash
                                              style={{ cursor: "pointer" }}
                                              className="ml-1"
                                              onClick={() =>
                                                handleDeleteClick(item._id)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                {isEditing ? "Edit Owner" : "Add New Owner"}
                <h4 className="text-success text-center"> {successMessage}</h4>
              </h4>

              <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="companyName"
                        className="col-sm-5 col-form-label"
                      >
                        Company Name
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="companyName"
                          maxLength={256}
                          placeholder="ABC Company"
                          {...register("companyName", {
                            required: "Company Name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />

                        {errors.companyName && (
                          <p className="text-danger">
                            {errors.companyName.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="primaryName"
                        className="col-sm-5 col-form-label"
                      >
                        Primary Name
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          placeholder="John"
                          className="form-control"
                          id="primaryName"
                          maxLength={256}
                          {...register("primaryName", {
                            required: "Primary name is required",
                            validate: (value) => validateName(value, "name"),
                          })}
                        />
                        {errors.primaryName && (
                          <p className="text-danger">
                            {errors.primaryName.message}
                          </p>
                        )}
                      </div>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handleNameModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                      >
                        Title/Surname
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          maxLength={256}
                          placeholder="Luther"
                          {...register("title", {
                            required: "Title is required",
                            validate: (value) => validateName(value, "Title"),
                          })}
                        />
                        {errors.title && (
                          <p className="text-danger"> {errors.title.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="employmentLevel"
                        className="col-sm-5 col-form-label"
                      >
                        Employment Level
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="employmentLevel"
                          value="Owner"
                          {...register("employmentLevel", { required: true })}
                          readOnly
                        />
                        {errors.employmentLevel && (
                          <p className="text-danger">
                            Employment Level is required
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="primaryContactNumber"
                        className="col-sm-5 col-form-label"
                      >
                        Contact Number
                      </label>
                      <div className="col-sm-6">
                        <Controller
                          name="primaryContactNumber"
                          control={control}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                preferredCountries={"us"}
                                // className={`w-100 ${
                                //   errors.primaryContactNumber
                                //     ? "is-invalid"
                                //     : ""
                                // }`}
                                value={field.value}
                                onChange={(primaryContactNumber) =>
                                  field.onChange(primaryContactNumber)
                                }
                                onBlur={field.onBlur}
                                inputProps={{ required: true }}
                                disableDialCodeAndPrefix={true}
                                disableFocusAfterCountrySelect={false}
                                hideDropdown={true}
                                disableCountryGuess={true}
                              />
                              {errors.primaryContactNumber && (
                                <p className="text-danger">
                                  {errors.primaryContactNumber.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                        {/* )} */}
                      </div>
                      <button
                        type="button"
                        className="m-2 btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handlePrimaryNumberModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-sm-5 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          maxLength={256}
                          placeholder="abc@gmail.com"
                          {...register("email", {
                            required: "Email is required",
                            validate: validateEmail,
                          })}
                          onChange={(e) => {
                            setError("");
                            e.target.value = e.target.value;
                          }}
                        />

                        {errors.email && (
                          <p className="text-danger">{errors.email.message}</p>
                        )}
                        {error && (
                          <p className="text-danger">{error}</p> // Show custom error message here
                        )}
                      </div>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handleEmailModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">Owner</label>
                      <div className="col-sm-7 pt-2">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isOwner"
                            value="Yes"
                            {...register("isOwner", { required: true })}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isOwner"
                            value="No"
                            {...register("isOwner", { required: true })}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        {errors.isOwner && (
                          <p className="text-danger">
                            Owner status is required
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="percentOwn"
                        className="col-sm-5 col-form-label"
                      >
                        Percent Own
                      </label>
                      <div className="col-sm-7">
                        <div className="row align-items-center">
                          <div className="col-7 input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="percentOwn"
                              placeholder="75"
                              {...register("percentOwn", {
                                required: "Percentage Own is required",
                                validate: validatePercentOwn,
                                valueAsNumber: true,
                              })}
                              max="100"
                              min="1"
                              step="1" // Ensures only whole numbers are accepted
                              onInput={(e) => {
                                let value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                if (value.length > 3) {
                                  value = value.slice(0, 3);
                                }
                                if (Number(value) > 100) {
                                  value = "100";
                                }
                                e.target.value = value;
                              }}
                            />

                            <div className="input-group-prepend">
                              <div className="input-group-text">%</div>
                            </div>
                          </div>
                          {errors.percentOwn && (
                            <p className="text-danger">
                              {errors.percentOwn.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <label
                        htmlFor="streetAddress"
                        className="col-sm-4 col-form-label"
                      >
                        Street Address
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="streetAddress"
                          maxLength={256}
                          placeholder="143, 7th Street, Cannes Road"
                          {...register("streetAddress", { required: true })}
                        />
                        {errors.streetAddress && (
                          <p className="text-danger">
                            Street Address is required
                          </p>
                        )}
                      </div>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        data-target="#primaryContactModal"
                        onClick={handleAddressModelOpen}
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="suiteNumber"
                        className="col-sm-4 col-form-label"
                      >
                        Suite Number
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="suiteNumber"
                          maxLength={128}
                          placeholder="STE 200"
                          {...register("suiteNumber", {
                            required: "Please Enter suite number",
                            validate: validateSuiteNumber,
                          })}
                        />
                        {errors.suiteNumber && (
                          <p className="text-danger">
                            {errors.suiteNumber.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="city" className="col-sm-4 col-form-label">
                        City
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Los Angeles"
                          id="city"
                          maxLength={128}
                          {...register("city", {
                            required: "Please enter city",
                            validate: (value) => validateName(value, "city"),
                          })}
                        />
                        {errors.city && (
                          <p className="text-danger">{errors.city.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="state"
                        className="col-sm-4 col-form-label"
                      >
                        State
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="California"
                          id="state"
                          maxLength={128}
                          {...register("state", {
                            required: "Please enter state",
                            validate: (value) => validateName(value, "state"),
                          })}
                        />
                        {errors.state && (
                          <p className="text-danger">{errors.state.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="zip" className="col-sm-4 col-form-label">
                        Zip Code
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="zip"
                          maxLength={5}
                          placeholder="25145"
                          {...register("zip", {
                            required: "Please enter zipcode",
                            validate: validateZipCode,
                          })}
                        />
                        {errors.zip && (
                          <p className="text-danger">{errors.zip.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-2 col-md-3 col-sm-4">
                    Primary Responsibility
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <textarea
                      className="form-control"
                      id="primaryResponsibility"
                      rows={4}
                      maxLength={256}
                      placeholder="Enter primary responsibility"
                      {...register("primaryResponsibility", {
                        required: "Primary Responsibility is required",
                      })}
                    />
                    {errors.primaryResponsibility && (
                      <p className="text-danger">
                        {errors.primaryResponsibility.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">
                      {isEditing ? "Save Owner" : "Add New Owner"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {openNameModel && (
        <PrimaryNameModel
          show={openNameModel}
          onClose={handleNameModelClose}
          onSave={handleSavePrimaryName}
          primaryName={primaryName}
        />
      )}
      <AddressModel
        show={openAddressModel}
        onClose={handleAddressModelClose}
        onSave={handleSaveStreetAddress}
        addressState={addressState}
      />
      <EmailModel
        show={openEmailModel}
        onClose={handleEmailModelClose}
        onSave={handleSaveEmail}
        email={email}
      />
      <PrimaryNumberModel
        show={openPrimaryNumberModel}
        onClose={handlePrimaryNumberModelClose}
        onSave={handleSavePrimaryContactNumber}
        primaryContactNumber={primaryContactNumber}
      />
    </div>
  );
};

export default Owners;
