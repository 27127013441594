import React from "react";
import { ArrowUpRight } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import FormatNumber from "../utils/FormatNumber";

const CallHistory = ({
  history,
  makeCall,
  setSearchHistory,
  total,
  getMoreHistory,
  fetchHistory,
  limit,
}) => {
  const getNumberFromArray = (phone) => {
    let returnData = "";
    for (let index = 0; index < phone.length; index++) {
      const element = phone[index];
      returnData = `${returnData} ${returnData ? "," : ""} ${
        element.value.includes("+1")
          ? element.value.split("+1")[1]
          : element.value
      }`;
    }
    return returnData;
  };
  return (
    <>
      <div className="col-lg-4 col-md-6 mb-3 align-self-stretch">
        <div className="card h-100">
          <div className="card-body">
            <h4 className="page-title text-truncate text-dark font-weight-medium mb-3">
              Call History
            </h4>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="input-group">
                  <input
                    className="form-control border-right-0 border form-rounded"
                    type="text"
                    id="SearchHistory"
                    placeholder="Search History"
                    maxLength={256}
                    onChange={(e) => setSearchHistory(e.target.value)}
                  />
                  <span className="input-group-append">
                    <div className="input-group-text bg-transparent form-rounded">
                      <i className="fa fa-search" />
                    </div>
                  </span>
                </div>
              </div>
              {/*/Col*/}
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-12">
                <ul className="call_history_list" id="call_history_list">
                  <InfiniteScroll
                    dataLength={history.length} //This is important field to render the next data
                    next={getMoreHistory}
                    hasMore={total >= limit}
                    scrollableTarget="call_history_list"
                    loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={fetchHistory}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8595; Pull down to refresh
                      </h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593; Release to refresh
                      </h3>
                    }
                  >
                    {history.map((item) => (
                      <li>
                        <span className="left_icon">
                          <ArrowUpRight className="feather-icon" />
                        </span>
                        <span className="call_number">
                          <p>
                            {item.contact[0]?.fullName
                              ? item.contact[0]?.fullName
                              : ""}
                          </p>
                          <p>
                            {item.phone.includes("+1")
                              ? item.phone.split("+1")[1]
                              : item.phone}
                          </p>
                          <small>{timeSince(new Date(item.createdAt))}</small>
                        </span>
                        <span
                          className="right_icon"
                          onClick={() => makeCall(item.phone)}
                        >
                          <i className="material-symbols-outlined">call</i>
                        </span>
                      </li>
                    ))}
                  </InfiniteScroll>
                </ul>
              </div>

              {/*/Col*/}
            </div>
            {/*/Row*/}
          </div>

          {/*/Card Body*/}
        </div>
        {/*/Card*/}
      </div>
    </>
  );
};
function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
function timeSinceDuration(seconds) {
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
export default CallHistory;
