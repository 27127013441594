import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import "./personal.css";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSocialSecurity,
  validateNumber,
  validateMedicareNumber,
  validatePercentOwn,
  validateURL,
} from "../../validation/Validation";
import { PhoneInput } from "react-international-phone";

const UserProfile = () => {
  const { jsonApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [dob, setDob] = useState(null);
  const [anniversary, setAnniversary] = useState(null);
  const [partA, setPartA] = useState(null);
  const [partB, setPartB] = useState(null);
  const [partC, setPartC] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({});
  const {
    fields: personalFieldsPhone,
    append: appendPersonalPhone,
    remove: removePersonalPhone,
  } = useFieldArray({
    control,
    name: "phone",
  });

  const {
    fields: personalFieldsEmail,
    append: appendPersonalEmail,
    remove: removePersonalEmail,
  } = useFieldArray({
    control,
    name: "email",
  });
  console.log(errors);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const onSubmit = async (data) => {
    const dataWithUserId = {
      ...data,
      userId: searchParams.get("id"),
      type: "Personal",
    };

    console.log("Data with userId:", dataWithUserId);

    // Make API call to update user data
    const response = await jsonApi(
      appConstant.updateUserSpouse,
      null,
      dataWithUserId,
      null
    );

    console.log("Response:", response);

    if (response.status === "success") {
      console.log("Data submitted successfully");
      reset();
      console.log("addadd", reset());
    } else {
      console.error("Unexpected response:", response);
    }
  };

  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
      console.log(data.phone);
      if (data.phone.length === 0) {
        setValue("phone", [{ value: "", type: "Personal" }]);
      }
      if (data.email.length === 0) {
        setValue("email", [{ value: "", type: "Personal" }]);
      }
    }
  };

  useEffect(() => {
    if (dob) {
      const calculateAge = (dateOfBirth) => {
        var today = new Date();
        var birthDate = new Date(dateOfBirth); // create a date object directly from `dob1` argument
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };
      const age = calculateAge(dob);
      setError("");
      setValue("age", age);
    }
  }, [dob, setValue]);

  const today = new Date(); // Current date
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const handleDateChange = (date, type) => {
    if (date instanceof Date && !isNaN(date)) {
      if (type === "dob") {
        setDob(date);
        setValue("dob", date, { shouldValidate: true });
      } else if (type === "anniversary") {
        setAnniversary(date);
        setValue("weddingAnniversary", date, { shouldValidate: true });
      } else if (type === "partA") {
        setPartA(date);
        setValue("partAEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partB") {
        setPartB(date);
        setValue("partBEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partC") {
        setPartC(date);
        setValue("partCEnrollmentDate", date, {
          shouldValidate: true,
        });
      }

      setError("");
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter fill the necessary details!";
    }
    return "";
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col">
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      {hasError && (
                        <div className="alert alert-danger text-center">
                          {getGeneralErrorMessage()}
                        </div>
                      )}
                      <div class="col-lg-12">
                        <div className="row">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Contact Details
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="fullName">fullName</label>
                                <input
                                  type="text"
                                  maxLength={256}
                                  required
                                  className={`form-control borderStyle ${
                                    errors.fullName
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="fullName"
                                  placeholder="John Luther"
                                  {...register("fullName", {
                                    required: false,
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "fullName")
                                        : null,
                                  })}
                                />

                                {errors.name && (
                                  <p className="text-danger">
                                    {errors.name.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="form-group"
                                  style={{ width: "100%" }}
                                >
                                  <label htmlFor="cellNo">Phone Number</label>
                                  {personalFieldsPhone.map((item, index) => (
                                    <Controller
                                      key={index}
                                      name={`phone.${index}.value`}
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <div className="d-flex ">
                                            <PhoneInput
                                              preferredCountries={"us"}
                                              value={field.value}
                                              inputProps={{ required: true }}
                                              onChange={(value) =>
                                                field.onChange(value)
                                              }
                                              style={{ width: "100%" }}
                                              onBlur={field.onBlur}
                                              disableDialCodeAndPrefix={true}
                                              disableFocusAfterCountrySelect={
                                                false
                                              }
                                              hideDropdown={true}
                                              disableCountryGuess={true}
                                              placeholder="(989) 898-9898"
                                              className=" borderStyle mb-2"
                                            />

                                            {index !== 0 ? (
                                              <button
                                                type="button"
                                                class="btn btn-outline-primary btn-circle m-2"
                                                onClick={() => {
                                                  removePersonalPhone(index);
                                                }}
                                                style={{ color: "red" }}
                                              >
                                                <i className="fa fa-minus"></i>
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                class="btn btn-outline-primary btn-circle m-2"
                                                style={{ visibility: "hidden" }}
                                              >
                                                <i className="fa fa-minus"></i>
                                              </button>
                                            )}
                                          </div>
                                          {errors.phone && (
                                            <p className="text-danger">
                                              {
                                                errors?.phone[index]?.value
                                                  .message
                                              }
                                            </p>
                                          )}
                                        </>
                                      )}
                                    />
                                  ))}
                                </div>
                                <div className="d-flex justify-content-center  align-items-center pe-4">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-circle"
                                    data-toggle="modal"
                                    data-target="#primaryContactModal"
                                    onClick={() => {
                                      appendPersonalPhone({
                                        value: "",
                                        type: "Personal",
                                      });
                                    }}
                                  >
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="form-group"
                                  style={{ width: "100%" }}
                                >
                                  <label htmlFor="personalEmail">Email</label>
                                  {personalFieldsEmail.map((item, index) => (
                                    <>
                                      <div className="d-flex ">
                                        <input
                                          type="email"
                                          className={`form-control borderStyle mb-2 ${
                                            errors.email
                                              ? "border border-danger"
                                              : ""
                                          }`}
                                          id="email"
                                          maxLength={256}
                                          placeholder="abc@gmail.com"
                                          {...register(`email.${index}.value`, {
                                            validate: validateEmail,
                                          })}
                                        />

                                        {index !== 0 ? (
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-circle m-2"
                                            onClick={() => {
                                              removePersonalEmail(index);
                                            }}
                                            style={{ color: "red" }}
                                          >
                                            <i className="fa fa-minus"></i>
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-circle m-2"
                                            style={{ visibility: "hidden" }}
                                          >
                                            <i className="fa fa-minus"></i>
                                          </button>
                                        )}
                                      </div>
                                      {errors?.email && (
                                        <p className="text-danger">
                                          {errors?.email[index]?.value.message}
                                        </p>
                                      )}
                                    </>
                                  ))}
                                </div>
                                <div className="d-flex justify-content-center  align-items-center pe-4">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-circle"
                                    data-toggle="modal"
                                    data-target="#primaryContactModal"
                                    onClick={() => {
                                      appendPersonalEmail({
                                        value: "",
                                        type: "Personal",
                                      });
                                    }}
                                  >
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="socialSecurity">
                                  Social Security #
                                </label>

                                <input
                                  id="socialSecurity"
                                  type="text"
                                  maxLength={256}
                                  placeholder="123-45-6789"
                                  className={`form-control borderStyle ${
                                    errors.socialSecurity
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("socialSecurity", {
                                    validate: validateSocialSecurity,
                                  })}
                                />
                                {errors.socialSecurity && (
                                  <p className="text-danger">
                                    {errors.socialSecurity.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="citizenshipStatus">
                                  Citizenship Status
                                </label>

                                <select
                                  id="citizenshipStatus"
                                  className={`form-control borderStyle ${
                                    errors.citizenshipStatus
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("citizenshipStatus", {})}
                                >
                                  <option value="">Select</option>
                                  <option value="US Citizen">US Citizen</option>
                                  <option value="LPR/Green Card (Legal Permanent Resident)">
                                    LPR/Green Card (Legal Permanent Resident)
                                  </option>
                                  <option value="F-1 Visa (Student or Work Visa)">
                                    F-1 Visa (Student or Work Visa)
                                  </option>
                                  <option value="B-1 or B-2 Visa">
                                    B-1 or B-2 Visa
                                  </option>
                                  <option value="Undocumented Immigrant">
                                    Undocumented Immigrant
                                  </option>
                                </select>
                                {errors.citizenshipStatus && (
                                  <div className="text-danger">
                                    {errors.citizenshipStatus.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="gender">Gender</label>
                                <select
                                  id="gender"
                                  className={`form-control borderStyle ${
                                    errors.gender ? "border border-danger" : ""
                                  }`}
                                  {...register("gender", {})}
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Prefer not to respond">
                                    Prefer not to respond
                                  </option>
                                </select>
                                {errors.gender && (
                                  <div className="text-danger">
                                    {errors.gender.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="dob">DOB</label>
                                <div
                                  style={{ display: "block", width: "100%" }}
                                >
                                  <DatePicker
                                    selected={dob}
                                    onChange={(date) =>
                                      handleDateChange(date, "dob")
                                    }
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    customInput={
                                      <input
                                        type="text"
                                        maxLength={256}
                                        className={`form-control borderStyle ${
                                          errors.dob
                                            ? "border border-danger"
                                            : ""
                                        }`}
                                        placeholder="DD/MM/YYYY"
                                        {...register("dob", {})}
                                      />
                                    }
                                  />
                                </div>
                                {errors.dob && (
                                  <p className="text-danger">
                                    {errors.dob.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="age">Age</label>
                                <div className="row">
                                  <div className="col-7 input-group">
                                    <input
                                      id="age"
                                      type="text"
                                      maxLength={5}
                                      className="form-control border-style"
                                      {...register("age", {})}
                                      readOnly
                                    />
                                  </div>
                                  <div className="input-group-text">yrs.</div>
                                </div>
                                {errors.age && (
                                  <div className="invalid-feedback">
                                    {errors.age.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="clientRank">Client Rank</label>

                                <select
                                  id="clientRank"
                                  className={`form-control borderStyle ${
                                    errors.clientRank
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("clientRank", {})}
                                >
                                  <option value="">Select</option>
                                  <option value="Platinum">Platinum</option>
                                  <option value="Gold">Gold</option>
                                  <option value="Special">Special</option>
                                  <option value="Former">Former</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="relationshipType">
                                  Relationship Type
                                </label>

                                <select
                                  id="relationshipType"
                                  className={`form-control borderStyle ${
                                    errors.relationshipType
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("relationshipType", {})}
                                >
                                  <option value="">Select</option>
                                  <option value="Single">Single</option>
                                  <option value="Married">Married</option>
                                  <option value="Cohabitation (Living Together)">
                                    Cohabitation (Living Together)
                                  </option>
                                  <option value="Separated">Separated</option>
                                  <option value="Divorced">Divorced</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="weddingAnniversary">
                                  Wedding Anniversary
                                </label>

                                <DatePicker
                                  selected={anniversary}
                                  onChange={(date) =>
                                    handleDateChange(date, "anniversary")
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  maxDate={maxDate}
                                  customInput={
                                    <input
                                      type="text"
                                      className={`form-control borderStyle ${
                                        errors.weddingAnniversary
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      placeholder="DD/MM/YYYY"
                                      {...register("weddingAnniversary", {})}
                                    />
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="yearOfMarriage">
                                  Year of Marriage
                                </label>

                                <div className="input-group">
                                  <input
                                    id="yearOfMarriage"
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.yearOfMarriage
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    {...register("yearOfMarriage")}
                                  />
                                  <div className="input-group-text">yrs.</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="driversLicense">
                                  Drivers License #
                                </label>

                                <input
                                  id="driversLicense"
                                  type="text"
                                  placeholder="AB1234432C43"
                                  maxLength={128}
                                  className={`form-control borderStyle ${
                                    errors.driversLicense
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("driversLicense", {
                                    validate: validateNumber,
                                  })}
                                />
                                {errors.driversLicense && (
                                  <p className="text-danger">
                                    {errors.driversLicense.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="driversLicenseState">
                                  Drivers License State
                                </label>

                                <input
                                  id="driversLicenseState"
                                  type="text"
                                  maxLength={256}
                                  placeholder="US"
                                  className={`form-control borderStyle ${
                                    errors.driversLicenseState
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("driversLicenseState", {})}
                                />
                                {errors.driversLicenseState && (
                                  <p className="text-danger">
                                    {errors.driversLicenseState.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
                            Primary Residence
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="streetAddress">
                                  Street Add.
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidenceStreetAddress
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="streetAddress"
                                  maxLength={256}
                                  placeholder="1435 Cedarwood Drive, San Mateo, CA, 89521"
                                  {...register("streetAddress", {})}
                                />

                                {errors.primaryResidenceStreetAddress && (
                                  <p className="text-danger">
                                    {
                                      errors.primaryResidenceStreetAddress
                                        .message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidenceCity
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="Los Angeles"
                                  id="city"
                                  maxLength={256}
                                  {...register("city", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "city")
                                        : null,
                                  })}
                                />

                                {errors.primaryResidenceCity && (
                                  <p className="text-danger">
                                    {errors.primaryResidenceCity.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="suiteNo">Suite #</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidenceSuiteNo
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="suiteNumber"
                                  maxLength={256}
                                  placeholder="STE 200"
                                  {...register("suiteNo", {
                                    validate: validateSuiteNumber,
                                  })}
                                />

                                {errors.primaryResidenceSuiteNo && (
                                  <p className="text-danger">
                                    {errors.primaryResidenceSuiteNo.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="state">State</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidenceState
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="California"
                                  maxLength={256}
                                  id="state"
                                  {...register("state", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "state")
                                        : null,
                                  })}
                                />
                                {errors.primaryResidenceState && (
                                  <p className="text-danger">
                                    {errors.primaryResidenceState.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="zip">Zip</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidenceZip
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="zip"
                                  maxLength={5}
                                  placeholder="78458"
                                  {...register("zip", {
                                    validate: validateZipCode,
                                  })}
                                />
                                {errors.primaryResidenceZip && (
                                  <p className="text-danger">
                                    {errors.primaryResidenceZip.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="yearsThere">Years There</label>

                                <div className="input-group">
                                  <input
                                    id="yearsThere"
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.primaryResidenceYearsThere
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    {...register("yearsThere", {
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Years There must be a number",
                                      },
                                    })}
                                  />
                                  <div className="input-group-text">yrs.</div>
                                  {errors.primaryResidenceYearsThere && (
                                    <p className="text-danger">
                                      {
                                        errors.primaryResidenceYearsThere
                                          ?.message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      {/* end row*/}
                    </div>
                  </div>
                  {/* multi-column ordering */}
                </div>
              </div>
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-auto">
                              <h4 className="page-title text-truncate text-dark font-weight-medium">
                                Work Info
                              </h4>
                            </div>
                            <div className="col"></div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              {/* <div className="col-lg-4"> */}
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyName
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="companyName"
                                  maxLength={256}
                                  placeholder="Enter company name"
                                  {...register("companyName", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "name")
                                        : null,
                                  })}
                                />
                                {errors.companyName && (
                                  <p className="text-danger">
                                    {errors.companyName?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="position">Position</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyPosition
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="position"
                                  maxLength={256}
                                  placeholder="Manager"
                                  {...register("companyPosition", {})}
                                />
                                {errors.companyPosition && (
                                  <p className="text-danger">
                                    {errors.companyPosition.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="directNumber">Direct #</label>
                                <Controller
                                  name="companyDirectNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="corporateNumber">
                                  {" "}
                                  Corporate #
                                </label>
                                <Controller
                                  name="corporateNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="companyExtensionNumber">
                                  {" "}
                                  Corporate Extension Number
                                </label>
                                <Controller
                                  name="companyExtensionNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="workEmail">Email</label>
                                <input
                                  type="email"
                                  className={`form-control borderStyle ${
                                    errors.workEmail
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="workEmail"
                                  maxLength={256}
                                  placeholder="abc@gmail.com"
                                  {...register("workEmail", {
                                    validate: validateEmail,
                                  })}
                                />
                                {errors.workEmail && (
                                  <p className="text-danger">
                                    {errors.workEmail?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="url">URL</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyUrl
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="url"
                                  maxLength={256}
                                  placeholder="www.abccompany.com"
                                  {...register("companyUrl", {
                                    validate: validateURL,
                                  })}
                                />
                                {errors.companyUrl && (
                                  <p className="text-danger">
                                    {errors.companyUrl?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* </div> */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="streetAddress">
                                  Street Address
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyStreetAddress
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="streetAddress"
                                  maxLength={256}
                                  placeholder="Enter street address"
                                  {...register("streetAddress", {})}
                                />
                                {errors.companyStreetAddress && (
                                  <p className="text-danger">
                                    {errors.companyStreetAddress.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="suiteNo">Suite #</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companySuiteNo
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="suiteNumber"
                                  maxLength={128}
                                  placeholder="STE 200"
                                  {...register("companySuiteNo", {
                                    validate: validateSuiteNumber,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="dept">Dept</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyDept
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="dept"
                                  maxLength={128}
                                  placeholder="IT Deptartment"
                                  {...register("companyDept", {})}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="mailStop">Mail Stop</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyMailStop
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="mailStop"
                                  maxLength={256}
                                  placeholder="BLDG-45-ROOM-101"
                                  {...register("companyMailStop", {
                                    pattern: {
                                      value: /^[a-zA-Z0-9\- ]{1,20}$/,
                                      message:
                                        "Mailstop must be between 1 and 20 characters and can include letters, numbers, hyphens, and spaces.",
                                    },
                                  })}
                                />
                                {errors.companyMailStop && (
                                  <p className="text-danger">
                                    {errors.companyMailStop?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyCity
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="city"
                                  maxLength={128}
                                  placeholder="Amsterdam"
                                  {...register("companyCity", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "city")
                                        : null,
                                  })}
                                />
                                {errors.companyCity && (
                                  <p className="text-danger">
                                    {errors.companyCity.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="state">State</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyState
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="Enter State"
                                  id="state"
                                  maxLength={256}
                                  {...register("companyState", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "state")
                                        : null,
                                  })}
                                />
                                {errors.companyState && (
                                  <p className="text-danger">
                                    {errors.companyState.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="zip">Zip</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.companyZip
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="zip"
                                  maxLength={5}
                                  placeholder="Enter zip code"
                                  {...register("companyZip", {
                                    validate: validateZipCode,
                                  })}
                                />
                                {errors.companyZip && (
                                  <p className="text-danger">
                                    {errors.companyZip.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="canCallAtWork">
                                  {" "}
                                  Can Call At Work
                                </label>
                                <div
                                  className={` form-control  ${
                                    errors.companyCanCallAtWork
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                >
                                  <div className="form-check form-check-inline ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="canCallAtWork"
                                      value={true}
                                      {...register("companyCanCallAtWork", {})}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="canCallAtWork"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerNo"
                                      value={false}
                                      {...register("companyCanCallAtWork")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerNo"
                                    >
                                      No
                                    </label>
                                  </div>
                                  {errors.companyCanCallAtWork && (
                                    <p className="text-danger">
                                      {errors.companyCanCallAtWork.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="owner">Owner</label>
                                <div
                                  className={` form-control  ${
                                    errors.companyOwner
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                >
                                  <div className="form-check form-check-inline ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerYes"
                                      value={true}
                                      {...register("companyOwner", {})}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerYes"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerNo"
                                      value={false}
                                      {...register("companyOwner")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerNo"
                                    >
                                      No
                                    </label>
                                  </div>
                                  {errors.companyOwner && (
                                    <p className="text-danger">
                                      {errors.companyOwner.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="companyPercentageOwn">
                                  Percentage Own
                                </label>
                                <div className="row">
                                  <div className="col-8 input-group">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.companyPercentageOwn
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      maxLength={4}
                                      id="companyPercentageOwn"
                                      {...register("companyPercentageOwn", {
                                        validate: validatePercentOwn,
                                        valueAsNumber: true,
                                      })}
                                      max="100"
                                      min="1"
                                      step="1" // Ensures only whole numbers are accepted
                                      onInput={(e) => {
                                        let value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        if (value.length > 3) {
                                          value = value.slice(0, 3);
                                        }
                                        if (Number(value) > 100) {
                                          value = "100";
                                        }
                                        e.target.value = value;
                                      }}
                                    />
                                  </div>
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">%</div>
                                  </div>
                                </div>
                                {errors.companyPercentageOwn && (
                                  <p className="text-danger">
                                    {errors.companyPercentageOwn.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Other Info
                          </h4>

                          <div className="row">
                            {/* First Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="petsName"
                                  className="col-sm-4 col-form-label"
                                >
                                  Pets Name
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    maxLength={256}
                                    type="text"
                                    className="form-control borderStyle"
                                    id="petsName"
                                    {...register("petsName")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="typeOfPets"
                                  className="col-sm-4 col-form-label"
                                >
                                  Type Of Pets
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.typeOfPets
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="typeOfPets"
                                    {...register("typeOfPets")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="hobbies"
                                  className="col-sm-4 col-form-label"
                                >
                                  Hobbies
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.hobbies
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="hobbies"
                                    {...register("hobbies")}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Second Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="medicarePlanType"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Plan Type
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.medicarePlanType
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicarePlanType"
                                    placeholder="Gold"
                                    {...register("medicarePlanType", {
                                      validate: (value) =>
                                        value
                                          ? validateName(value, "Plan Type")
                                          : null,
                                    })}
                                  />
                                  {errors.medicarePlanType && (
                                    <p className="text-danger">
                                      {errors.medicarePlanType.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicareCompany"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Company
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.medicareCompany
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicareCompany"
                                    placeholder="LIC"
                                    {...register("medicareCompany", {
                                      validate: (value) =>
                                        value
                                          ? validateName(value, "Company Name")
                                          : null,
                                    })}
                                  />
                                  {errors.medicareCompany && (
                                    <p className="text-danger">
                                      {errors.medicareCompany.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicare"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.medicare
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicare"
                                    placeholder="123-45-6789"
                                    {...register("medicare", {
                                      validate: validateMedicareNumber,
                                    })}
                                  />
                                  {errors.medicare && (
                                    <p className="text-danger">
                                      {errors.medicare.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Third Column */}
                            <div className="col-sm-4">
                              <p> Medicare Enrollment Dates</p>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicarePlanType"
                                  className="col-sm-3 col-form-label"
                                >
                                  Part A
                                </label>
                                <div className="col-sm-8">
                                  <DatePicker
                                    selected={partA}
                                    onChange={(date) =>
                                      handleDateChange(date, "partA")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    showIcon
                                    customInput={
                                      <input
                                        type="text"
                                        className="form-control form-control-sm borderStyle"
                                        placeholder="DD/MM/YYYY"
                                        id="partAMedicareEnrollmentDate"
                                        {...register(
                                          "partAMedicareEnrollmentDate"
                                        )}
                                      />
                                    }
                                  />
                                  {errors.otherInfo
                                    ?.partAMedicareEnrollmentDate && (
                                    <p className="text-danger">
                                      {
                                        errors.otherInfo
                                          ?.partAMedicareEnrollmentDate.message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicareCompany"
                                  className="col-sm-3 col-form-label"
                                >
                                  Part B
                                </label>
                                <div className="col-sm-8">
                                  <DatePicker
                                    selected={partB}
                                    onChange={(date) =>
                                      handleDateChange(date, "partB")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    showIcon
                                    customInput={
                                      <input
                                        type="text"
                                        className="form-control form-control-sm borderStyle"
                                        placeholder="DD/MM/YYYY"
                                        id="partBEnrollmentDate"
                                        {...register("partBEnrollmentDate")}
                                      />
                                    }
                                  />
                                  {errors.partBEnrollmentDate && (
                                    <p className="text-danger">
                                      {errors.partBEnrollmentDate.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicare"
                                  className="col-sm-3 col-form-label"
                                >
                                  Part C
                                </label>
                                <div className="col-sm-8">
                                  <DatePicker
                                    selected={partC}
                                    onChange={(date) =>
                                      handleDateChange(date, "partC")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    showIcon
                                    customInput={
                                      <input
                                        type="text"
                                        className="form-control form-control-sm borderStyle"
                                        placeholder="DD/MM/YYYY"
                                        id="partCEnrollmentDate"
                                        {...register("partCEnrollmentDate")}
                                      />
                                    }
                                  />
                                  {errors.partCEnrollmentDate && (
                                    <p className="text-danger">
                                      {errors.partCEnrollmentDate.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-sm-4">
                              <div className="form-group row">
                                <div className="col">
                                  <label
                                    htmlFor="medicareEnrollmentDates"
                                    className="col form-label mt-n2"
                                  >
                                    Medicare Enrollment Dates
                                  </label>

                                  <div className="form-row col-sm-10">
                                    <div className="display: block; width: 100%;">
                                      <label
                                        htmlFor="partAMedicareEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part A
                                      </label>

                                      <DatePicker
                                        selected={partA}
                                        onChange={(date) =>
                                          handleDateChange(date, "partA")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partAMedicareEnrollmentDate"
                                            {...register(
                                              "partAMedicareEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partAMedicareEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partAMedicareEnrollmentDate
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="display: block; width: 100%;">
                                      <label
                                        htmlFor="partBEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part B
                                      </label>

                                      <DatePicker
                                        selected={partB}
                                        onChange={(date) =>
                                          handleDateChange(date, "partB")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partBEnrollmentDate"
                                            {...register(
                                              "partBEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partBEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partBEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>

                                    <div className="display: block; width: 100%;">
                                      <label
                                        htmlFor="partCEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part C
                                      </label>

                                      <DatePicker
                                        selected={partC}
                                        onChange={(date) =>
                                          handleDateChange(date, "partC")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partCEnrollmentDate"
                                            {...register(
                                              "partCEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partCEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partCEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {/* Start Page Content */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        {/* Button Container */}
                        <div className="col-12 d-flex justify-content-center">
                          <div className="form-group row">
                            <button
                              type="submit"
                              className="next-step btn_green mx-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row*/}
                  </div>
                </div>
                {/* multi-column ordering */}
              </div>

              {/* multi-column ordering */}
            </div>
          </form>
        </div>
      </div>

      {/* End Container fluid  */}
    </>
  );
};

export default UserProfile;
