// const FormatNumber=(phoneNumber)=> {
//     if (!phoneNumber) return "No Data";

//     const cleanedNumber = phoneNumber.replace(/^\D+/g, "");
//     const number= cleanedNumber.replace(
//       /(\d{3})(\d{3})(\d{4})$/,
//       "($1) $2-$3");

// return number
//     // if (cleanedNumber.length < 10) return "No Data";

//     // return `+1 (${cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})$/, "$1")} ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)})`;
//   }

//   export default FormatNumber


const FormatNumber = (phoneNumber) => {
  if (!phoneNumber) return "No Data";

  const cleanedNumber = phoneNumber.replace(/^\D+/g, ""); // Clean non-digit characters

  if (cleanedNumber.length < 10) return "No Data"; // Check if the number is valid

  // Format number with the + sign and proper grouping
  const number = cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})$/,"+$1 ($2) $3-$4");

  return number;
};

export default FormatNumber;


{/* {item.primaryContactNumber
                                              ? `+ ${item.primaryContactNumber
                                                  .replace(/^\D+/g, "")
                                                  .replace(
                                                    /(\d{3})(\d{3})(\d{4})$/,
                                                    "($1) $2-$3"
                                                  )}`
                                              : "No Data"} */}