import React, {  useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useApis from "../../../apis/useApis";
import appConstant from "../../../json/appConstant.json";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  validateName,
  validateSocialSecurity,
  validateNumber,
} from "../../validation/Validation";

const DependentsForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const isEditMode = searchParams.get("isEditing") === "true";
  const [error, setError] = useState();

  const defaultDependent = {
    name: "",
    age: "",
    dob: "",
    gender: "",
    ssn: "",
    custodyType: "",
    relationshipType: "",
    driversLicense: "",
    driversLicenseState: "",
    yearsOfDriving: "",
    bloodRelativeInfo: "",
    biologicalParent: "",
    currentLivingProfile: "",
    gradeLevel: "",
    nameOfSchool: "",
    addressOfSchool: "",
    major: "",
    
  };

  const [dependents, setDependents] = useState([defaultDependent]);
  const { jsonApi } = useApis();

  // Handler to add a new dependent
  const addDependent = () => {
    setDependents([...dependents, defaultDependent]);
  };

  const removeDependent = (index) => {
    setDependents(dependents.filter((_, i) => i !== index));
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { dependents } });

  const onSubmit = async (data) => {
    const payload = {
      userId: searchParams.get("id"),
      dependents: data.dependents,
    };
    console.log("Submitting payload:", payload);
    const response = await jsonApi(
      appConstant.addDependent,
      null,
      payload,
      null
    );
    console.log(response);

    reset();

  };

  const handleDateChange = (date, index) => {
    if (date instanceof Date && !isNaN(date)) {
     
      setValue(`dependents.${index}.dob`, date, { shouldValidate: true });

      const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

      const age = calculateAge(date);
      setValue(`dependents.${index}.age`, age);
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const today = new Date(); // Current date
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );


  // Inline styles
  const cardStyle = { marginBottom: "1.5rem" };
  const formGroupStyle = { marginBottom: "1rem" };
  const formControlStyle = { width: "100%" };
  

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const populateForm = (data) => {
    // Set initial values for dependents if not already set
    if (data?.dependents) {
      data.dependents.forEach((dependent, index) => {
        Object.keys(dependent).forEach((key) => {
          setValue(`dependents[${index}].${key}`, dependent[key]);
         
        });
      });
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {dependents.map((dependent, index) => (
            <div
              className="col-lg-6 mb-3"
              key={dependent.index}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="card" style={cardStyle}>
                <div className="card-body">
                  <div className="dependent-section">
                    <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 d-flex justify-content-between align-items-center">
                      Dependent Details {index + 1}
                      <div className="button-group d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addDependent}
                        >
                          {isEditMode && index >= 0 ? "Add +" : "Show +"}
                        </button>
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={() => removeDependent(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </h4>
                  </div>

               
                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">Name</label>

                      <input
                        type="text"
                        placeholder="Alex Luther"
                        className="form-control borderStyle"
                        maxLength={256}
                        id="primaryName"
                        {...register(`dependents.${index}.name`, {
                          required: "Name is required",
                          validate: (value) => validateName(value, "name"),
                        })}
                        style={formControlStyle}
                      />
                      {errors.dependents?.[index]?.name && (
                        <p className="text-danger">
                          {errors.dependents[index].name.message}
                        </p>
                      )}
                    </div>

                    <div className="col-6">
                      <label className="col-form-label">DOB</label>

                      <DatePicker
                        selected={
                          getValues(`dependents.${index}.dob`)
                            ? new Date(getValues(`dependents.${index}.dob`))
                            : null
                        }
                        onChange={(date) => handleDateChange(date, index)}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={maxDate}
                        customInput={
                          <input
                            type="text"
                            maxLength={256}
                            className="form-control borderStyle"
                            placeholder="DD/MM/YYYY"
                            {...register(`dependents.${index}.dob`, {
                              required: "DOB is required",
                            })}
                          />
                        }
                        style={formControlStyle}
                      />
                      {errors.dependents?.[index]?.dob && (
                        <p className="text-danger">
                          {errors.dependents[index].dob.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">Age</label>
                      <input
                        type="text"
                        maxLength={256}
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.age`, {})}
                        style={formControlStyle}
                        readOnly
                      />
                      {errors.dependents?.[index]?.age && (
                        <p className="text-danger">
                          {errors.dependents[index].age.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="col-form-label">Gender</label>
                      <select
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.gender`, {
                          required: "Gender is required",
                        })}
                        style={formControlStyle}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to respond">
                          Prefer not to respond
                        </option>
                      </select>
                      {errors.dependents?.[index]?.gender && (
                        <p className="text-danger">
                          {errors.dependents[index].dob.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">
                        Social Security #
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="123-45-6789"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.ssn`, {
                          required: "SSN is required",
                          validate: validateSocialSecurity,
                        })}
                        style={formControlStyle}
                      />

                      {errors.dependents?.[index]?.ssn && (
                        <p className="text-danger">
                          {errors.dependents[index].ssn.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="col-form-label">Custody Type</label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="Mother/Father"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.custodyType`, {
                          required: "Custody Type is required",
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.custodyType && (
                        <p className="text-danger">
                          {errors.dependents[index].custodyType.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">
                        Relationship Type
                      </label>
                      <select
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.relationshipType`, {
                          required: "Relationship Type is required",
                        })}
                        style={formControlStyle}
                      >
                        <option value="">Select</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Cohabitation (Living Together)">
                          Cohabitation (Living Together)
                        </option>
                        <option value="Separated">Separated</option>
                        <option value="Divorced">Divorced</option>
                      </select>
                      {errors.dependents?.[index]?.relationshipType && (
                        <p className="text-danger">
                          {errors.dependents[index].relationshipType.message}
                        </p>
                      )}
                    </div>

                    <div className="col-6">
                      <label className="col-form-label">
                        Drivers License #
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="KL8782738"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.driversLicense`, {
                          required: "License number is required",
                          validate: validateNumber,
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.driversLicense && (
                        <p className="text-danger">
                          {errors.dependents[index].driversLicense.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                     <div className="col-6">
                      <label className="col-form-label">
                        Drivers License State
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="California"
                        className="form-control borderStyle"
                        {...register(
                          `dependents.${index}.driversLicenseState`,
                          {required: "License State is required",
                            validate: (value) =>
                              validateName(value, "state"), }
                        )}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.driversLicenseState && (
                        <p className="text-danger">
                          {errors.dependents[index].driversLicenseState.message}
                        </p>
                      )}
                    </div> 
                    <div className="col-6">
                      <label className="col-form-label">Years of Driving</label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="10"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.yearsOfDriving`, {
                          required: "Driving years is required",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Years There must be a number",
                            },
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.yearsOfDriving && (
                        <p className="text-danger">
                          {errors.dependents[index].yearsOfDriving.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">
                        Blood Relative Info
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder=""
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.bloodRelativeInfo`, {
                          required: "Blood Relative is required",
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.bloodRelativeInfo && (
                        <p className="text-danger">
                          {errors.dependents[index].bloodRelativeInfo.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="col-form-label">
                        Biological Parent
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="Yes/No"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.biologicalParent`, {
                          required: "Biological Parent is required",
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.biologicalParent && (
                        <p className="text-danger">
                          {errors.dependents[index].biologicalParent.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">
                        Current Living Profile
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="With Parents/ Away"
                        className="form-control borderStyle"
                        {...register(
                          `dependents.${index}.currentLivingProfile`,
                          { required: "Living Profile is required" }
                        )}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.currentLivingProfile && (
                        <p className="text-danger">
                          {errors.dependents[index].currentLivingProfile.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="col-form-label">Grade Level</label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="10th/12th/Grad"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.gradeLevel`, {
                          required: "Grade Level is required",
                        })}
                        style={formControlStyle}
                      />
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-6">
                      <label className="col-form-label">Name of School</label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="St. Xavier International"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.nameOfSchool`, {
                          required: "School name is required",
                          validate: (value) =>
                            validateName(value, "school name"),
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.nameOfSchool && (
                        <p className="text-danger">
                          {errors.dependents[index].nameOfSchool.message}
                        </p>
                      )}
                    </div>

                    <div className="col-6">
                      <label className="col-form-label">
                        Address of School
                      </label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="122,4th Street, Green Ford"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.addressOfSchool`, {
                          required: "Address is required",
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.addressOfSchool && (
                        <p className="text-danger">
                          {errors.dependents[index].addressOfSchool.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row" style={formGroupStyle}>
                    <div className="col-12">
                      <label className="col-form-label">Major</label>
                      <input
                        type="text"
                        maxLength={256}
                        placeholder="Science/Commerce"
                        className="form-control borderStyle"
                        {...register(`dependents.${index}.major`, {
                          required: "Major is required",
                        })}
                        style={formControlStyle}
                      />
                       {errors.dependents?.[index]?.major && (
                        <p className="text-danger">
                          {errors.dependents[index]?.major?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="form-group row">
              <button type="submit" className="next-step btn_green mx-2">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DependentsForm;
