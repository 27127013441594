import React, { useState } from "react";
import { Link } from "react-router-dom";

const AddBusinessPersonalContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const [selectedItem, setSelectedItem] = useState("Human Resources");
  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
  };

  return (
    <div className="col-12 business-contact">
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn-lg active"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Personal
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn-lg"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Business
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <Link
            to="add-personal-contact"
            className="btn waves-effect waves-light btn-rounded btn-sm btn-outline-primary mb-2"
          >
            + Add Personal Contact
          </Link>

          {/*Add Personal Contact Modal End*/}
          <div className="row">
            <ul className="nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to={"/business-contact/lead/client"}
                >
                  Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/business-contact/lead/prospects"}
                >
                  Prospects
                </Link>
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link to="personal/data" className="nav-link">
                  All
                </Link>
              </li>
              <li className="nav-item">
                <Link to="personal-favorites" className="nav-link ">
                  Favorites
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Wholesalers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Vendors
                </Link>
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" href="#">
                  Email
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Phone Call
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Contact Summary
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " href="#">
                  Notes
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Files
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Text
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Chat
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  US Postal Mail
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Video / Schedule Event
                </Link>
              </li>
            </ul>
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link active" to="#">
                  Contact Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="spouse-profiles" className="nav-link active">
                  Spouse Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="dependent-profile" className="nav-link">
                  Dependent Profile
                </Link>
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link className="nav-link " href="#">
                  Sale Summary
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Sale Opportunity
                </Link>
              </li>
            </ul>
            <ul className="nav">
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Quote <i className="fa fa-angle-down" aria-hidden="true" />
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link className="dropdown-item" href="#">
                    Medicare
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Life Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Auto Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Homeowners Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Solar Energy
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <Link to="add-business-contact">
            {" "}
            <button
              type="button"
              className="btn waves-effect waves-light btn-rounded btn-sm btn-outline-primary mb-2"
            >
              + Add Business Contact
            </button>
          </Link>

          <div className="row">
            <ul className="nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to={"/business-contact/lead/client"}
                >
                  Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/business-contact/lead/prospects"}
                >
                  Prospects
                </Link>
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link to="businessPersonalData" className="nav-link">
                  All
                </Link>
              </li>
              <li className="nav-item">
                <Link to="business-favorites" className="nav-link ">
                  Favorites
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Wholesalers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Vendors
                </Link>
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link to="businessEmailPage" className="nav-link active">
                  Email
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Phone Call
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Contact Summary
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " href="#">
                  Notes
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Files
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Text
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Chat
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  US Postal Mail
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Schedule Event
                </Link>
              </li>
            </ul>
            <ul className="nav">
              <li className="nav-item">
                <Link to="business-Information" className="nav-link active">
                  Business Information
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  onClick={toggleDropdown}
                  to="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={isOpen}
                >
                  {/* Human Resources */}
                  {selectedItem}
                  <i className="fa fa-angle-down px-1" aria-hidden="true" />
                </Link>
                {isOpen && (
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link
                      className="dropdown-item"
                      to="owners"
                      onClick={() => handleItemClick("Owners")}
                    >
                      Owners
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="key_executive"
                      onClick={() => handleItemClick("Key Executive")}
                    >
                      Key Executive
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="managers"
                      onClick={() => handleItemClick("Managers")}
                    >
                      Managers
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="employee"
                      onClick={() => handleItemClick("Employee")}
                    >
                      Employee
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="contactHumanData"
                      onClick={() => handleItemClick("All")}
                    >
                      All
                    </Link>
                  </div>
                )}
              </li>
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">
                  Sale Summary
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " href="#">
                  Sale Opportunity
                </Link>
              </li>
            </ul>
            <ul className="nav">
              <li>
                <span className="separator">|</span>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Quote <i className="fa fa-angle-down" aria-hidden="true" />
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link className="dropdown-item" href="#">
                    Medicare
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Life Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Auto Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Homeowners Insurance
                  </Link>
                  <Link className="dropdown-item" href="#">
                    Solar Energy
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBusinessPersonalContact;
