import React from "react";
import ModelUi from "../../../model/ModelUi";
import { useForm } from "react-hook-form";

const PrimaryNumberModel = ({
  show,
  onClose,
  onSave,
  primaryContactNumber,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      primaryContactNumberInput: primaryContactNumber, // Set initial value from props
    },
  });

  const onSubmit = (data) => {
    onSave(data.primaryContactNumberInput); // Call onSave with the new phone number
    reset();
    onClose();
  };

  return (
    <ModelUi
      show={show}
      handleClose={onClose}
      centered={true}
      title="Primary Contact Number"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="form-group row">
            <label
              htmlFor="primaryContactNumberInput"
              className="col-sm-5 col-form-label"
            >
              Primary Contact Primary Contact
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                placeholder="Please enter valid Primary Contact"
                className="form-control"
                id="primaryContactNumberInput"
                maxLength={256}
                keyboardType="numeric"
                {...register("primaryContactNumberInput", {
                  required: "Primary Contact Numer is required",
                  validate: (value) =>
                    /^\d+$/.test(value) || "Please enter only number",
                })}
              />
              {errors.primaryContactNumberInput && (
                <span className="text-danger">
                  {errors.primaryContactNumberInput.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">
            Save changes
          </button>
        </div>
      </form>
    </ModelUi>
  );
};

export default PrimaryNumberModel;
