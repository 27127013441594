import React, { useEffect, useState, useRef, useContext } from "react";
import personalData from "../../../json/personalData.json";
import PaginationComponent from "../../pagination/PaginationComponent";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PersonalFavButton from "./PersonalFavButton";
import { CallCenterContext } from "../../../context/CallCenterContext";
import ModelUi from "../../model/ModelUi";
import {
  getEmailFromArray,
  getNumberFromArray,
} from "../../../apis/commonFunction/commonFunction";

const AllDataPersonal = ({
  onEditChange,
  deleteUserDetail,
  classPage = true,
}) => {
  const { jsonApi, mediaApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const formRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [vcfFile, setVcfFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const [totalData, setTotalData] = useState(0);
  const [openImportContactModal, setOpenImportContactModal] = useState(false);
  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });
  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };
  const { makeCall } = useContext(CallCenterContext);
  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactPersonal,
        { page, limit, search: search },
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const navigate = useNavigate();

  const handleEditClick = (id) => {
    navigate(
      `/business-contact/edit-personal-contact/contact-profile?id=${id}`
    );
    if (onEditChange) {
      onEditChange(true);
    }

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDeleteClick = async (id) => {
    await deleteUserDetail(id);
    fetchContactHuman();
  };
  const contactVcfFileHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("contact", vcfFile); // data.logo is a FileList, get the first file
      const response = await mediaApi(
        appConstant.importContacts,
        null,
        formData,
        null
      );

      if (!response.error) {
        setSuccessMessage(response.message);
        setError("");
        fetchContactHuman();
      } else {
        setError(response.error.message || "An error occurred");
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
    setVcfFile(null);
  };

  const formattedDate = (timestamp, formatDob) => {
    if (!timestamp) return "N/A";
    const dob = moment(timestamp);
    return dob.format(formatDob);
  };

  return (
    // <div className="container-fluid">
    <div className={classPage ? "container-fluid" : "card"}>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-10"></div>
        <div className="col-2">
          <button
            data-toggle="modal"
            data-target="#personalContact"
            className="page-link"
          >
            Import Contact{" "}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          {/* <div className="card"> */}
          <div className="card-body custom-field-button">
            <div className="row ">{/*/Col*/}</div>
            {/*/Row*/}

            {/*/Row*/}
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <div
                    id="default_order_wrapper"
                    className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                  >
                    <PaginationComponent
                      setPage={setPage}
                      totalData={totalData}
                      limit={limit}
                      setLimit={setLimit}
                      page={page}
                      setSearch={setSearch}
                    >
                      <div className="row">
                        <div className="col-12 mb-2">
                          <table
                            id="default_order"
                            className="table table-hover table-striped display no-wrap w-100"
                          >
                            <thead>
                              <tr>
                                {personalData.headers1.map((header, index) => (
                                  <th
                                    scope="col"
                                    style={{ textAlign: "center" }}
                                    key={index}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {allContactDetails &&
                              allContactDetails.length > 0 ? (
                                allContactDetails.map((item, index) => {
                                  return (
                                    <tr
                                      style={{ textAlign: "center" }}
                                      key={index}
                                    >
                                      <td>
                                        {(page - 1) * limit + index + 1}
                                        <span className="p-1">
                                          {" "}
                                          <PersonalFavButton
                                            item={item}
                                            init={fetchContactHuman}
                                          />
                                        </span>
                                      </td>

                                      <td>{item.fullName || "No Data"}</td>
                                      <td>
                                        {getNumberFromArray(item?.phone) ||
                                          "No Data"}
                                      </td>
                                      <td>
                                        {getEmailFromArray(item?.email) ||
                                          "No Data"}
                                      </td>
                                      <td>
                                        {formattedDate(
                                          item?.dob,
                                          "DD/MM/YYYY"
                                        ) || "No Data"}
                                      </td>
                                      <td>
                                        <Edit2
                                          style={{ cursor: "pointer" }}
                                          className="ml-1"
                                          onClick={() =>
                                            handleEditClick(item._id)
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Trash
                                          style={{ cursor: "pointer" }}
                                          className="ml-1"
                                          onClick={() =>
                                            handleDeleteClick(item._id)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <span
                                          className="right_icon"
                                          onClick={() =>
                                            item?.phone.length === 1
                                              ? makeCall(item.phone[0].value)
                                              : setOpenModelForCallHandler(
                                                  item.phone
                                                )
                                          }
                                        >
                                          <i className="material-symbols-outlined">
                                            call
                                          </i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="5">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </PaginationComponent>
                  </div>
                </div>
              </div>
              {/*/Col*/}
            </div>
            {/*/Row*/}
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="col-11 my-2 d-flex justify-content-end">
        <div
          className="modal fade"
          id="personalContact"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title text-dark font-weight-medium"
                  id="exampleModalLabel"
                >
                  Personal Contact
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  // onClick={() => setSelectedFileName("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="mb-3 text-black font-weight-bold">
                      Add Contacts Via VCF File
                    </h5>
                    <p className="mb-3 text-black">
                      To Contacts via CSV, upload a CSV file containing the
                      contact's name,phone,etc
                    </p>

                    <h6 className="mb-3 text-black font-weight-bold">
                      File Upload (.vcf)
                    </h6>
                    <div className="row">
                      <div className="col-sm-5 mb-3">
                        <span className="btn btn-secondary btn-file">
                          Choose a File{" "}
                          <input
                            type="file"
                            accept=".vcf"
                            onChange={(e) => setVcfFile(e.target.files[0])}
                          />
                        </span>
                      </div>
                      <div className="col-sm-7 mb-3"></div>
                      {/* <div className="col-sm-7 mb-3 d-flex align-items-center">
                        {selectedFileName && (
                          <div className="file-name ml-3">
                            {selectedFileName}
                          </div>
                        )}
                      </div> */}
                      {/* {excelError ? (
                        <div className="col-12 my-4">
                          <div class="alert alert-danger" role="alert">
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}
                      <div className="col-12 my-4">
                        <button
                          type="button"
                          onClick={contactVcfFileHandler}
                          className="btn btn-primary"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={!vcfFile}
                        >
                          Bulk Add contacts
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*/Col*/}
                </div>
                {/*/Row*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default AllDataPersonal;
