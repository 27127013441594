import React, { useContext, useEffect, useState } from "react";
import "./personal.css";
import { useForm, Controller } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Context } from "../../../context/Context";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSocialSecurity,
  validateNumber,
  validateMedicareNumber,
  validatePercentOwn,
  validateURL,
} from "../../validation/Validation";
import { PhoneInput } from "react-international-phone";

const SpouseDetails = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [dob, setDob] = useState(null);
  const [anniversary, setAnniversary] = useState(null);
  const [partA, setPartA] = useState(null);
  const [partB, setPartB] = useState(null);
  const [partC, setPartC] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const { userData } = useContext(Context);
  console.log(userData);

  const onSubmit = async (data) => {
    console.log("data", data);
    const dataWithType = {
      userId: searchParams.get("id"),
      type: "spouse",
      ...data,
    };
    console.log(dataWithType);

    const response = await jsonApi(
      appConstant.updateUserSpouse,
      null,
      dataWithType,
      null
    );
    if (!response.error) {
      setSuccessMessage(response.message);

      reset();
    } else {
      setError(response.message);
    }
  };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      populateForm(res.data?.data?.spouse);
      setData(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const populateForm = (data) => {
    if (data?.contactDetail) {
      Object.keys(data.contactDetail).forEach((key) => {
        setValue(`contactDetail.${key}`, data.contactDetail[key]);
      });
    }
    if (data?.primaryResidence) {
      Object.keys(data?.primaryResidence).forEach((key) => {
        setValue(`primaryResidence.${key}`, data.primaryResidence[key]);
      });
    }
    if (data?.workInfo) {
      Object.keys(data?.workInfo).forEach((key) => {
        setValue(`workInfo.${key}`, data.workInfo[key]);
      });
    }
    if (data?.otherInfo) {
      Object.keys(data?.otherInfo).forEach((key) => {
        setValue(`otherInfo.${key}`, data.otherInfo[key]);
      });
    }
  };

  useEffect(() => {
    if (dob) {
      const calculateAge = (dateOfBirth) => {
        var today = new Date();
        var birthDate = new Date(dateOfBirth); // create a date object directly from `dob1` argument
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        console.log(age);
        return age;
      };
      const age = calculateAge(dob);
      setError("");
      setValue("contactDetail.age", age);
    }
  }, [dob, setValue]);

  const today = new Date(); // Current date
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter all the details!";
    }
    // setError("");
    return "";
  };

  const handleDateChange = (date, type) => {
    if (date instanceof Date && !isNaN(date)) {
      if (type === "dob") {
        setDob(date);
        setValue("contactDetail.dob", date, { shouldValidate: true });
      } else if (type === "anniversary") {
        setAnniversary(date);
        setValue("contactDetail.weddingAnniv", date, { shouldValidate: true });
      } else if (type === "partA") {
        setPartA(date);
        setValue("otherInfo.partAEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partB") {
        setPartB(date);
        setValue("otherInfo.partBEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partC") {
        setPartC(date);
        setValue("otherInfo.partCEnrollmentDate", date, {
          shouldValidate: true,
        });
      }

      setError("");
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col">
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      {/* Start Page Content */}
                      {hasError && (
                        <div className="alert alert-danger text-center">
                          {/* {getGeneralErrorMessage()} */}
                          {getGeneralErrorMessage()}
                        </div>
                      )}

                      <div class="col-lg-12">
                        <div className="row">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Contact Details
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control borderStyle ${
                                    errors.name ? "border border-danger" : ""
                                  }`}
                                  id="name"
                                  placeholder="John Luther"
                                  {...register("name", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "name"),
                                  })}
                                />

                                {errors.name && (
                                  <p className="text-danger">
                                    {errors.name.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="cellNo">Cell No</label>
                              <input
                                type="text"
                                placeholder="+198787878765"
                                maxLength={15}
                                className={`form-control borderStyle ${
                                  errors.cellNo
                                    ? "border border-danger"
                                    : ""
                                }`}
                                {...register("cellNo", {
                                  required: true,
                                  validate: validatePhoneNumberWithCountryCode,
                                })}
                              />

                              {errors.cellNo && (
                                <p className="text-danger">
                                  {errors.cellNo.message}
                                </p>
                              )}
                            </div>
                          </div> */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="cellNo">Cell No</label>
                                <Controller
                                  name="contactDetail.cellNo"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        inputProps={{ required: true }}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                      {errors.contactDetail?.cellNo && (
                                        <p className="text-danger">
                                          {errors.contactDetail?.cellNo.message}
                                        </p>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="personalEmail">Email</label>
                                <input
                                  type="personalEmail"
                                  className={`form-control borderStyle ${
                                    errors.personalEmail
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="email"
                                  maxLength={256}
                                  placeholder="abc@gmail.com"
                                  {...register("personalEmail", {
                                    required: true,
                                    validate: validateEmail,
                                  })}
                                />
                                {errors.personalEmail && (
                                  <p className="text-danger">
                                    {errors.personalEmail.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="socialSecurity">
                                  Social Security #
                                </label>

                                <input
                                  id="socialSecurity"
                                  type="text"
                                  maxLength={256}
                                  placeholder="123-45-6789"
                                  className={`form-control borderStyle ${
                                    errors.socialSecurity
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("socialSecurity", {
                                    required: true,
                                    validate: validateSocialSecurity,
                                  })}
                                />
                                {errors.socialSecurity && (
                                  <p className="text-danger">
                                    {errors.socialSecurity.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="citizenshipStatus">
                                  Citizenship Status
                                </label>

                                <select
                                  id="citizenshipStatus"
                                  className={`form-control borderStyle ${
                                    errors.citizenshipStatus
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("citizenshipStatus", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  <option value="US Citizen">US Citizen</option>
                                  <option value="LPR/Green Card (Legal Permanent Resident)">
                                    LPR/Green Card (Legal Permanent Resident)
                                  </option>
                                  <option value="F-1 Visa (Student or Work Visa)">
                                    F-1 Visa (Student or Work Visa)
                                  </option>
                                  <option value="B-1 or B-2 Visa">
                                    B-1 or B-2 Visa
                                  </option>
                                  <option value="Undocumented Immigrant">
                                    Undocumented Immigrant
                                  </option>
                                </select>
                                {errors.citizenshipStatus && (
                                  <div className="text-danger">
                                    {errors.citizenshipStatus.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="gender">Gender</label>
                                <select
                                  id="gender"
                                  className={`form-control borderStyle ${
                                    errors.gender ? "border border-danger" : ""
                                  }`}
                                  {...register("gender", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Prefer not to respond">
                                    Prefer not to respond
                                  </option>
                                </select>
                                {errors.gender && (
                                  <div className="text-danger">
                                    {errors.gender.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="dob">DOB</label>

                                <DatePicker
                                  selected={dob}
                                  onChange={(date) =>
                                    handleDateChange(date, "dob")
                                  }
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  maxDate={maxDate}
                                  customInput={
                                    <input
                                      type="text"
                                      maxLength={256}
                                      className={`form-control borderStyle ${
                                        errors.dob ? "border border-danger" : ""
                                      }`}
                                      placeholder="DD/MM/YYYY"
                                      {...register("dob", {
                                        required: true,
                                      })}
                                    />
                                  }
                                />
                                {errors.dob && (
                                  <p className="text-danger">
                                    {errors.dob.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="age">Age</label>
                                <div className="row">
                                  <div className="col-7 input-group">
                                    <input
                                      id="age"
                                      type="text"
                                      maxLength={5}
                                      className="form-control border-style"
                                      {...register("age", {
                                        required: true,
                                      })}
                                      readOnly
                                    />
                                  </div>
                                  <div className="input-group-text">yrs.</div>
                                </div>
                                {errors.age && (
                                  <div className="invalid-feedback">
                                    {errors.age.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="clientRank">Client Rank</label>

                                <select
                                  id="clientRank"
                                  className={`form-control borderStyle ${
                                    errors.clientRank
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("clientRank", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  <option value="Platinum">Platinum</option>
                                  <option value="Gold">Gold</option>
                                  <option value="Special">Special</option>
                                  <option value="Former">Former</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="relationshipType">
                                  Relationship Type
                                </label>

                                <select
                                  id="relationshipType"
                                  className={`form-control borderStyle ${
                                    errors.relationshipType
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("relationshipType", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  <option value="Single">Single</option>
                                  <option value="Married">Married</option>
                                  <option value="Cohabitation (Living Together)">
                                    Cohabitation (Living Together)
                                  </option>
                                  <option value="Separated">Separated</option>
                                  <option value="Divorced">Divorced</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="weddingAnniv">
                                  Wedding Anniversary
                                </label>

                                <DatePicker
                                  selected={anniversary}
                                  onChange={(date) =>
                                    handleDateChange(date, "anniversary")
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  maxDate={maxDate}
                                  customInput={
                                    <input
                                      type="text"
                                      className={`form-control borderStyle ${
                                        errors.weddingAnniv
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      placeholder="DD/MM/YYYY"
                                      {...register("weddingAnniv", {})}
                                    />
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="yearOfMarriage">
                                  Year of Marriage
                                </label>

                                <div className="input-group">
                                  <input
                                    id="yearOfMarriage"
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.yearOfMarriage
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    {...register("yearOfMarriage")}
                                  />
                                  <div className="input-group-text">yrs.</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="driversLicense">
                                  Drivers License #
                                </label>

                                <input
                                  id="driversLicense"
                                  type="text"
                                  placeholder="AB1234432C43"
                                  maxLength={128}
                                  className={`form-control borderStyle ${
                                    errors.driversLicense
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("driversLicense", {
                                    required: true,
                                    validate: validateNumber,
                                  })}
                                />
                                {errors.driversLicense && (
                                  <p className="text-danger">
                                    {errors.driversLicense.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="driversLicenseState">
                                  Drivers License State
                                </label>

                                <input
                                  id="driversLicenseState"
                                  type="text"
                                  maxLength={256}
                                  placeholder="US"
                                  className={`form-control borderStyle ${
                                    errors.driversLicenseState
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  {...register("driversLicenseState", {
                                    required: true,
                                  })}
                                />
                                {errors.driversLicenseState && (
                                  <p className="text-danger">
                                    {errors.driversLicenseState.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
                            Primary Residence
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="streetAddress">
                                  Street Add.
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidence?.streetAddress
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="streetAddress"
                                  maxLength={256}
                                  placeholder="1435 Cedarwood Drive, San Mateo, CA, 89521"
                                  {...register(
                                    "primaryResidence.streetAddress",
                                    {
                                      required: true,
                                    }
                                  )}
                                />

                                {errors.primaryResidence?.streetAddress && (
                                  <p className="text-danger">
                                    {
                                      errors.primaryResidence?.streetAddress
                                        .message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidence?.city
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="Los Angeles"
                                  id="city"
                                  maxLength={256}
                                  {...register("primaryResidence.city", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "city"),
                                  })}
                                />

                                {errors.primaryResidence?.city && (
                                  <p className="text-danger">
                                    {errors.primaryResidence?.city.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="suiteNo">Suite #</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidence?.suiteNo
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="suiteNumber"
                                  maxLength={256}
                                  placeholder="STE 200"
                                  {...register("primaryResidence.suiteNo", {
                                    required: true,
                                    validate: validateSuiteNumber,
                                  })}
                                />

                                {errors.primaryResidence?.suiteNo && (
                                  <p className="text-danger">
                                    {errors.primaryResidence?.suiteNo.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="state">State</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidence?.state
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="California"
                                  maxLength={256}
                                  id="state"
                                  {...register("primaryResidence.state", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "state"),
                                  })}
                                />
                                {errors.primaryResidence?.state && (
                                  <p className="text-danger">
                                    {errors.primaryResidence?.state.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="zip">Zip</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.primaryResidence?.zip
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="zip"
                                  maxLength={5}
                                  placeholder="78458"
                                  {...register("primaryResidence.zip", {
                                    required: true,
                                    validate: validateZipCode,
                                  })}
                                />
                                {errors.primaryResidence?.zip && (
                                  <p className="text-danger">
                                    {errors.primaryResidence?.zip.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="yearsThere">Years There</label>

                                <div className="input-group">
                                  <input
                                    id="yearsThere"
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.primaryResidence?.yearsThere
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    {...register(
                                      "primaryResidence.yearsThere",
                                      {
                                        required: true,
                                        pattern: {
                                          value: /^[0-9]+$/,
                                          message:
                                            "Years There must be a number",
                                        },
                                      }
                                    )}
                                  />
                                  <div className="input-group-text">yrs.</div>
                                  {errors.primaryResidence?.yearsThere && (
                                    <p className="text-danger">
                                      {
                                        errors.primaryResidence?.yearsThere
                                          ?.message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group business-search">
                                <label htmlFor="linkedPersonalContact">
                                  Linked Personal Contact
                                </label>
                                <input
                                  id="linkedPersonalContact"
                                  type="text"
                                  className="typeaheadcomp form-control text-truncate"
                                  placeholder=""
                                  maxLength={256}
                                  {...register(
                                    "primaryResidence.linkedPersonalContact"
                                  )}
                                />
                                <i
                                  className="form-control-icon"
                                  data-feather="search"
                                />
                              </div>
                            </div>

                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      {/* end row*/}
                    </div>
                  </div>
                  {/* multi-column ordering */}
                </div>
              </div>
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-auto">
                              <h4 className="page-title text-truncate text-dark font-weight-medium">
                                Work Info
                              </h4>
                            </div>
                            <div className="col"></div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              {/* <div className="col-lg-4"> */}
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.companyName
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="companyName"
                                  maxLength={256}
                                  placeholder="Enter company name"
                                  {...register("workInfo.companyName", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "name"),
                                  })}
                                />
                                {errors.workInfo?.companyName && (
                                  <p className="text-danger">
                                    {errors.workInfo?.companyName?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="position">Position</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.position
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="position"
                                  maxLength={256}
                                  placeholder="Manager"
                                  {...register("workInfo.position", {
                                    required: true,
                                  })}
                                />
                                {errors.workInfo?.position && (
                                  <p className="text-danger">
                                    {errors.workInfo.position.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="directNumber">Direct #</label>
                                <Controller
                                  name="workInfo.directNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        inputProps={{ required: true }}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="corporateNumber">
                                  {" "}
                                  Corporate #
                                </label>
                                <Controller
                                  name="workInfo.corporateNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        inputProps={{ required: true }}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="extensionNumber">
                                  {" "}
                                  Corporate #
                                </label>
                                <Controller
                                  name="workInfo.extensionNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        preferredCountries={"us"}
                                        value={field.value}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        onBlur={field.onBlur}
                                        inputProps={{ required: true }}
                                        disableDialCodeAndPrefix={true}
                                        disableFocusAfterCountrySelect={false}
                                        hideDropdown={true}
                                        disableCountryGuess={true}
                                        placeholder="(989) 898-9898"
                                        className=" borderStyle"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="workEmail">Email</label>
                                <input
                                  type="email"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.workEmail
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="workEmail"
                                  maxLength={256}
                                  placeholder="abc@gmail.com"
                                  {...register("workInfo.workEmail", {
                                    required: true,
                                    validate: validateEmail,
                                  })}
                                />
                                {errors.workInfo?.workEmail && (
                                  <p className="text-danger">
                                    {errors.workInfo?.workEmail?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="url">URL</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.url
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="url"
                                  maxLength={256}
                                  placeholder="www.abccompany.com"
                                  {...register("workInfo.url", {
                                    required: true,
                                    validate: validateURL,
                                  })}
                                />
                                {errors.workInfo?.url && (
                                  <p className="text-danger">
                                    {errors.workInfo?.url?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* </div> */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="streetAddress">
                                  Street Address
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.streetAddress
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="streetAddress"
                                  maxLength={256}
                                  placeholder="Enter street address"
                                  {...register("workInfo.streetAddress", {
                                    required: true,
                                  })}
                                />
                                {errors.workInfo?.streetAddress && (
                                  <p className="text-danger">
                                    {errors.workInfo.streetAddress.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="suiteNo">Suite #</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.suiteNo
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="suiteNumber"
                                  maxLength={128}
                                  placeholder="STE 200"
                                  {...register("workInfo.suiteNo", {
                                    required: true,
                                    validate: validateSuiteNumber,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="dept">Dept</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.dept
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="dept"
                                  maxLength={128}
                                  placeholder="IT Deptartment"
                                  {...register("workInfo.dept", {
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="mailStop">Mail Stop</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.mailStop
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="mailStop"
                                  maxLength={256}
                                  placeholder="BLDG-45-ROOM-101"
                                  {...register("workInfo.mailStop", {
                                    required: true,
                                    pattern: {
                                      value: /^[a-zA-Z0-9\- ]{1,20}$/,
                                      message:
                                        "Mailstop must be between 1 and 20 characters and can include letters, numbers, hyphens, and spaces.",
                                    },
                                  })}
                                />
                                {errors.workInfo?.mailStop && (
                                  <p className="text-danger">
                                    {errors.workInfo?.mailStop?.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.city
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="city"
                                  maxLength={128}
                                  placeholder="Amsterdam"
                                  {...register("workInfo.city", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "city"),
                                  })}
                                />
                                {errors.workInfo?.city && (
                                  <p className="text-danger">
                                    {errors.workInfo.city.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="state">State</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.state
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  placeholder="Enter State"
                                  id="state"
                                  maxLength={256}
                                  {...register("workInfo.state", {
                                    required: true,
                                    validate: (value) =>
                                      validateName(value, "state"),
                                  })}
                                />
                                {errors.workInfo?.state && (
                                  <p className="text-danger">
                                    {errors.workInfo.state.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label htmlFor="zip">Zip</label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.zip
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="zip"
                                  maxLength={5}
                                  placeholder="Enter zip code"
                                  {...register("workInfo.zip", {
                                    required: true,
                                    validate: validateZipCode,
                                  })}
                                />
                                {errors.workInfo?.zip && (
                                  <p className="text-danger">
                                    {errors.workInfo.zip.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="canCallAtWork">
                                  Can Call At Work
                                </label>
                                <input
                                  type="text"
                                  className={`form-control borderStyle ${
                                    errors.workInfo?.canCallAtWork
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="canCallAtWork"
                                  maxLength={256}
                                  placeholder="Yes/No"
                                  {...register("workInfo.canCallAtWork", {
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="owner">Owner</label>
                                <div
                                  className={` form-control  ${
                                    errors.workInfo?.owner
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                >
                                  <div className="form-check form-check-inline ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerYes"
                                      value="Yes"
                                      {...register("workInfo.owner", {
                                        required: true,
                                      })}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerYes"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerNo"
                                      value="No"
                                      {...register("workInfo.owner")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerNo"
                                    >
                                      No
                                    </label>
                                  </div>
                                  {errors.workInfo?.owner && (
                                    <p className="text-danger">
                                      {errors.workInfo?.owner.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="percentageOwn">
                                  Percentage Own
                                </label>
                                <div className="row">
                                  <div className="col-8 input-group">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.workInfo?.percentOwn
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      maxLength={4}
                                      id="percentageOwn"
                                      {...register("percentOwn", {
                                        required: "Percentage Own is required",
                                        validate: validatePercentOwn,
                                        valueAsNumber: true,
                                      })}
                                      max="100"
                                      min="1"
                                      step="1" // Ensures only whole numbers are accepted
                                      onInput={(e) => {
                                        let value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        if (value.length > 3) {
                                          value = value.slice(0, 3);
                                        }
                                        if (Number(value) > 100) {
                                          value = "100";
                                        }
                                        e.target.value = value;
                                      }}
                                    />
                                  </div>
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">%</div>
                                  </div>
                                </div>
                                {errors.workInfo?.percentageOwn && (
                                  <p className="text-danger">
                                    {errors.workInfo.percentageOwn.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="linkedBusinessContact">
                                  Linked Business Contact
                                </label>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="linkedBusinessContactYes"
                                    value="Yes"
                                    {...register(
                                      "workInfo.linkedBusinessContact",
                                      { required: true }
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="linkedBusinessContactYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="linkedBusinessContactNo"
                                    value="No"
                                    {...register(
                                      "workInfo.linkedBusinessContact"
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="linkedBusinessContactNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group business-search">
                                <label htmlFor="search">Search</label>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="typeaheadcomp form-control text-truncate borderStyle"
                                    id="search"
                                    maxLength={256}
                                    placeholder="Search for business contact"
                                    {...register("workInfo.search")}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Other Info
                          </h4>

                          <div className="row">
                            {/* First Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="petsName"
                                  className="col-sm-4 col-form-label"
                                >
                                  Pets Name
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    maxLength={256}
                                    type="text"
                                    className="form-control borderStyle"
                                    id="petsName"
                                    {...register("otherInfopetsName")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="typeOfPets"
                                  className="col-sm-4 col-form-label"
                                >
                                  Type Of Pets
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.typeOfPets
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="typeOfPets"
                                    {...register("otherInfo.typeOfPets")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="hobbies"
                                  className="col-sm-4 col-form-label"
                                >
                                  Hobbies
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.hobbies
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="hobbies"
                                    {...register("otherInfo.hobbies")}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Second Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="medicarePlanType"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Plan Type
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicarePlanType
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicarePlanType"
                                    placeholder="Gold"
                                    {...register("otherInfo.medicarePlanType", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "Plan Type"),
                                    })}
                                  />
                                  {errors.otherInfo?.medicarePlanType && (
                                    <p className="text-danger">
                                      {
                                        errors.otherInfo?.medicarePlanType
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicareCompany"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Company
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicareCompany
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicareCompany"
                                    placeholder="LIC"
                                    {...register("otherInfo.medicareCompany", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "Company Name"),
                                    })}
                                  />
                                  {errors.otherInfo?.medicareCompany && (
                                    <p className="text-danger">
                                      {
                                        errors.otherInfo?.medicareCompany
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicare"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicare
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    id="medicare"
                                    placeholder="123-45-6789"
                                    {...register("otherInfo.medicare", {
                                      required: true,
                                      validate: validateMedicareNumber,
                                    })}
                                  />
                                  {errors.otherInfo?.medicare && (
                                    <p className="text-danger">
                                      {errors.otherInfo?.medicare.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Third Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <div className="col">
                                  <label
                                    htmlFor="medicareEnrollmentDates"
                                    className="col form-label mt-n2"
                                  >
                                    Medicare Enrollment Dates
                                  </label>
                                  <div className="form-row col-sm-10">
                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partAMedicareEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part A
                                      </label>

                                      <DatePicker
                                        selected={partA}
                                        onChange={(date) =>
                                          handleDateChange(date, "partA")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partAMedicareEnrollmentDate"
                                            {...register(
                                              "otherInfo.partAMedicareEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partAMedicareEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partAMedicareEnrollmentDate
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partBEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part B
                                      </label>

                                      <DatePicker
                                        selected={partB}
                                        onChange={(date) =>
                                          handleDateChange(date, "partB")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partBEnrollmentDate"
                                            {...register(
                                              "otherInfo.partBEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partBEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partBEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>

                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partCEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part C
                                      </label>

                                      <DatePicker
                                        selected={partC}
                                        onChange={(date) =>
                                          handleDateChange(date, "partC")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partCEnrollmentDate"
                                            {...register(
                                              "otherInfo.partCEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partCEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partCEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {/* Start Page Content */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        {/* Button Container */}
                        <div className="col-12 d-flex justify-content-center">
                          <div className="form-group row">
                            <button
                              type="submit"
                              className="next-step btn_green mx-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row*/}
                  </div>
                </div>
                {/* multi-column ordering */}
              </div>

              {/* multi-column ordering */}
            </div>
          </form>
        </div>
      </div>

      {/* End Container fluid  */}
    </>
  );
};

export default SpouseDetails;
